<template>
  <div class="activity-config" v-loading="show_loading">
    <div  class="modular" style="margin-top: 15px">
      <p class="modular-title" style="margin-bottom: 15px">签到二维码</p>
      <div class="modular-content"  v-if="codeForm.mp || codeForm.h5">
        <!--        <el-button size="small" @click="handleShowCode(0)">生成二维码</el-button>-->
        <div class="code">
          <div class="content-box">
            <div class="item-box">
              <div class="code-box" style="border-radius: 50%">
                <el-image fit="cover" :src="codeForm.mp"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codeForm.mp"
                  download="签到码"
                >
                  下载小程序码
                </a>
              </el-button>
            </div>
            <div class="item-box">
              <div class="code-box" >
                <el-image fit="cover" :src="codeForm.h5"></el-image>
              </div>
              <el-button type="text">
                <a
                  style="text-decoration: none"
                  :href="codeForm.h5"
                  download="签到码"
                >
                下载 H5 微网站二维码
                </a>
              </el-button>
            </div>
          </div>
        </div>

        <!-- <el-form-item label="链接" v-if="status == 1">
            <el-input disabled style="width: 75%" v-model="codeForm.link" :rows="3" placeholder="请输入链接">
            </el-input>
            <el-button style="margin-left: 10px" type="text" @click="copy(codeForm.link)">
              复制
            </el-button>
          </el-form-item> -->
      </div>
      <div v-else-if="!show_loading">暂无签到二维码</div>
    </div>

    <!-- <div style="padding: 0 4px">
      <div class="config-title">留言设置</div>
      <div class="second-text">留言审核</div>
      <div>
        <el-radio v-model="configForm.check_config" @change="changeCommentConfig" :label="0">先发后审</el-radio>
        <el-radio v-model="configForm.check_config" @change="changeCommentConfig" :label="1">先审后发</el-radio>
      </div>
    </div> -->

    <!-- <div style="padding: 0 4px">
      <div class="config-title">展示设置</div>
      <div class="second-text">展示配置</div>
      <div>
        <el-checkbox v-model="configForm.is_home" :true-label="1" :false-label="0" @change="handleChangeShow">
          展示在首页</el-checkbox>
        <el-checkbox v-model="configForm.is_join_brang" :true-label="1" :false-label="0" disabled>展示所属组织
        </el-checkbox>
        <el-checkbox v-model="configForm.is_on_brand" :true-label="1" :false-label="0"
          @change="handleChangeShow">展示上级组织</el-checkbox>
      </div>
    </div> -->

    <!-- <div class="modular" style="margin-top: 15px">
      <p class="modular-title" style="margin-bottom: 15px">添加发布人员</p>
      <div class="modular-content">
        <div style="display: flex">
          <el-button size="small" @click="showSelectMember = true">选择{{'会员'|saasVersionKeyword}}</el-button>
          <el-button size="small" @click="showAddPhone = true">添加游客</el-button>
          <el-button size="small" @click="handleShowCode(1)">游客发布人员入口</el-button>
        </div>
      </div>
    </div>

    <el-table style="margin-top: 20px" class="thead-light" stripe :data="member_list">
      <el-table-column label="头像" width="100">
        <template slot-scope="scope">
          <el-image style="width: 32px;height: 32px;border-radius: 50%" :src="scope.row.avatar"></el-image>
        </template>
      </el-table-column>
      <el-table-column v-for="(item,index) in memberTable" :key="index" :label="item.label"
        :prop="item.prop?item.prop:'-'" :min-width="item.min_width">
        <template slot-scope="scope">
          <span>{{ scope.row[item.prop] | placeholder}}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户类型" width="100">
        <template slot-scope="scope">
          <span>{{scope.row.title_name?'会员':'游客'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用" width="100">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"
            @change="handleChangeEnable(scope.row.id, $event)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="80" fixed="right">
        <template slot-scope="scope">
          <el-button style="color: #3576FF" size="small" type="text" @click="handleDelUser(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table> -->

    <SelectMember
      v-model="showSelectMember"
      :Members="member_list"
      @changeSelect="changeSelect"
    >
    </SelectMember>
    <ActivityConfig
      :showCode="showCode"
      :activity_id="id"
      :status="code_status"
      @closeCode="showCode = false"
    ></ActivityConfig>
    <AddTourist
      :showAddPhone="showAddPhone"
      :activity_id="id"
      @closeAddPhone="closeAddPhone"
    ></AddTourist>
  </div>
</template>

<script>
import {
  activityConfig,
  checkConfig,
  showConfig,
  addEditorByUser,
  openEnable,
  delUser,
  activityQrCode,
} from "@/modules/activity/api/activity-config/activity-configuration.js";
import SelectMember from "@/modules/common/components/MemberSelector";
import ActivityConfig from "@/modules/activity/components/ActivityConfig/ActivityConfig.vue";
import AddTourist from "@/modules/activity/components/ActivityConfig/AddTourist.vue";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      id: 0,
      show_loading: false,
      configForm: {
        check_config: "0",
        is_home: 0,
        is_join_brang: 1,
        is_on_brand: 0,
      },
      memberTable: [
        { label: "用户名称", prop: "name", min_width: 100 },
        { label: "会内职务", prop: "title_name", min_width: 200 },
        { label: "手机号码", prop: "phone", min_width: 120 },
      ],
      member_list: [],
      showSelectMember: false,
      showCode: false,
      showAddPhone: false,
      code_status: 0,

      codeForm: {
        mp: "",
        h5: "",
        link: "",
      },
    };
  },
  components: { SelectMember, ActivityConfig, AddTourist },
  created() {
    this.id = this.$route.params.id;
    // this.getActivityConfig();
    this.getActivityQrCode()
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  methods: {
    closeAddPhone(data) {
      this.showAddPhone = false;
      if (data) {
        this.getActivityConfig();
      }
    },
    handleShowCode(status) {
      this.code_status = status;
      this.showCode = true;
    },
    handleDelUser(id) {
      this.$confirm("确定要删除该发布人？删除后不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delUser({ id }).then((res) => {
            this.getActivityConfig();
          });
        })
        .catch(() => {});
    },
    handleChangeEnable(id, e) {
      this.show_loading = true;
      let data = {
        id,
        status: e,
      };
      openEnable(data).then((res) => {
        this.show_loading = false;
      });
    },
    changeSelect(data) {
      this.showSelectMember = false;
      let arr = [];
      data.forEach((e) => {
        arr.push(e.user_id);
      });
      let obj = {
        id: this.id,
        user_ids: arr,
      };
      addEditorByUser(obj).then((res) => {
        this.getActivityConfig();
      });
    },
    handleChangeShow() {
      this.show_loading = true;
      let data = {
        id: this.id,
        is_home: this.configForm.is_home,
        is_join_brang: this.configForm.is_join_brang,
        is_on_brand: this.configForm.is_on_brand,
      };
      showConfig(data).then((res) => {
        this.show_loading = false;
      });
    },
    changeCommentConfig() {
      this.show_loading = true;
      let data = {
        id: this.id,
        check_config: this.configForm.check_config,
      };
      checkConfig(data).then((res) => {
        this.show_loading = false;
      });
    },
    getActivityConfig() {
      this.show_loading = true;
      activityConfig({ id: this.id }).then((res) => {
        this.configForm = res.data.activity;
        this.member_list = res.data.editors;
        this.show_loading = false;
      });
    },
    // 签到二维码
    getActivityQrCode(){
      this.show_loading = true
      activityQrCode({ id: this.id })
      .then((res) => {
        this.codeForm.mp = res.data.mp;
        this.codeForm.h5 = res.data.h5;
        this.show_loading = false
        console.log(this.codeForm)
      })
      .catch(() => {
        this.show_loading =false
      });
    }   
  },
};
</script>

<style lang="scss" scoped>
.activity-config {
  padding-top: 0;
  min-height: 50vh;
}
.config-title {
  padding: 59px 0 19px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #e9e9e9;
}
.second-text {
  font-size: 16px;
}

.code {
  display: inline-block;

.content-box {
  background-color: #f2f2f2;
  padding: 16px;
  display: flex;
  .item-box {
    width: 180px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .code-box {
      width: 180px;
      height: 180px;
      margin: 10px 0;

      background-color: #fff;

      overflow: hidden;
    }
  }
  .item-box  +  .item-box  {
    margin-left: 40px;
  }
}
}
</style>
