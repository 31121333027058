<template>
  <div class="activity-tag" v-loading="showLoading">
    <reminder
      :text-arr="[
        '前期准备：',
        '1、【签到打印标签】开关已开启',
        '2、【标签设计】已添加，确认标签的宽、高（可以在纸卷的卷芯内侧查看尺寸），填入尺寸后需手动点击【变更尺寸】，调整标签设计内容后点击保存',
        '3、【打印机】已添加，设备id可在打印机开机后在屏幕上查看',
        ' ',
        '使用期间：',
        '1、在安卓设备上登录微信，微信所绑定的手机号需要与管理后台账号个人中心的联系电话一致',
        '2、在小程序的个人中心-活动标签进入活动列表，选择本活动',
        '3、确保打印机已开机，且未被其他设备所连接',
        '4、确保安卓设备蓝牙和定位已开启',
        '5、连接设备，点击右上角设备图标，进入设备列表，读取本页面配置好的【打印机】列表，点击连接，操作完成后返回',
        '6、自动打印功能，需要待签到用户扫描【设备签到码】完成签到，获取途径为：第5点设备图标旁边的二维码或本页面-打印机-下载二维码',
        '7、打印期间建议安卓设备不能离开小程序，否则可能无法接收自动打印信息，无法完成自动打印，手动打印不受影响',
        ' ',
        '打印机注意事项：',
        '调整纸卷位置，纸卷需要放置在纸仓最左侧，以确保打印内容符合预设的位置',
      ]"
    ></reminder>

    <el-form
      v-if="form"
      ref="detailForm"
      size="medium"
      :model="form"
      :rules="rules"
      label-position="right"
      label-width="135px"
      class="form small-form"
      @submit.native.prevent
    >
      <el-form-item label="签到打印标签：">
        <el-switch
          v-model="form.print_tag"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
        <p class="form-tip info">
          <i class="el-icon-info" style="font-size: 15px; color: #c0c4cc"></i
          >开启后扫码可以打印签到标签，打印标签需要购买打印机，可以联系工作人员购买
        </p>
      </el-form-item>
      <el-form-item label="标签设计：" prop="print_design">
        <input v-show="false" type="hidden" :value="form.print_design" />
        <cert-design-enter
          :preview.sync="cert_design.preview_image"
          info="编辑标签"
          height="160px"
          @click="
            () => {
              openMyKonva = true
              isEdit = !cert_design.preview_image
            }
          "
        />
        <div class="info">
          <i class="el-icon-info"></i>可上传标签背景图调整标签内容
        </div>
      </el-form-item>
      <el-form-item label="打印方式：" prop="print_type">
        <el-radio-group v-model="form.print_type">
          <el-radio :label="0">自动打印</el-radio>
          <el-radio :label="1">手动打印</el-radio>
        </el-radio-group>
        <p class="form-tip info">
          <i class="el-icon-info" style="font-size: 15px; color: #c0c4cc"></i>
          无论何种打印方式，在小程序端都可手动选择会员打印
        </p>
      </el-form-item>
      <el-form-item label="打印机：" prop="print_machines">
        <input type="hidden" :value="form.print_machines" />
        <SelectPrinter
          v-if="form.print_machines !== undefined"
          :list="form.print_machines"
          @setList="onPrintMachinesUpdate"
        >
        </SelectPrinter>
      </el-form-item>
    </el-form>

    <FixedActionBar>
      <el-button @click="$router.back()">取消</el-button>
      <el-button type="primary" @click="handleSave" :loading="saveLoading"
        >保存</el-button
      >
    </FixedActionBar>

    <!-- 标签编辑, 600*400 对应实际的 60*40 -->
    <MyKonva
      v-if="isDesignReady"
      v-model="openMyKonva"
      :templateData="[]"
      :configData.sync="cert_design.config"
      :preview.sync="cert_design.preview_image"
      :mobilePreview.sync="cert_design.mp_image"
      :uploadApi="uploadFile"
      :isNewAdd="isEdit"
      :canvas-width="cert_design.label_width * 10"
      :canvas-height="cert_design.label_height * 10"
      :custom="true"
      :custom-fixed-variable="customFixedVariable"
      dialog-width="1345px"
      @resize="onDesignResize"
      @save="handleSave"
    />
    <!-- 媒体库弹窗 -->
    <MediaSelector v-model="openMediaSelector" @select="onSelect" />
  </div>
</template>

<script>
import {
  TagInfo,
  saveTagInfo,
} from '@/modules/activity/api/activity-list/activity-tag.js'
/* 通用组件 */
import FixedActionBar from '@/base/layout/FixedActionBar'
import MediaWall from '@/modules/common/components/MediaWall.vue'

import SelectPrinter from '@/modules/activity/components/ActivityList/ActivityTag/SelectPrinter.vue'
import MyKonva from '@/base/components/MyKonva'
import MediaSelector from '@/modules/common/components/MemberSelector.vue'
import CertDesignEnter from '@/modules/cert-manage/components/add-edit/Design'

import upload from '@/base/utils/upload4'
import { customFixedVariable } from '@/modules/activity/data/customFixedVariable'
import { isArray } from '@/base/utils/tool.js'
import Reminder from '@/modules/common/components/Reminder'

const uploadFile = (data) => {
  return upload({
    configApi: '/admin/admin/media/requestUpload',
    method: 'post',
    data,
  })
}
export default {
  model: {
    prop: 'dataForm',
    event: 'updateForm',
  },
  components: {
    Reminder,
    FixedActionBar,
    SelectPrinter,
    MediaWall,
    CertDesignEnter,
    MyKonva,
    MediaSelector,
  },
  props: {
    dataForm: {
      type: Object,
    },
    data: {
      type: String,
    },
  },
  // 注入
  provide() {
    return {
      //打开媒体库的方法
      openMedia: (callback) => {
        this.openMediaSelector = true
        this.$designCallback = callback
      },
    }
  },

  data() {
    return {
      id: 0,
      openMyKonva: false, // 是否打开标签编辑弹窗
      openMediaSelector: false, //是否显示图片上传弹窗

      // 避免影响 canvas 大小的初始化
      isDesignReady: false,

      cert_design: {
        config: {},
        mp_image: '',
        preview_image: '',
        // mp_image:
        //   'https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20230919/b0675b8a36d8e46ebf6da213ac1cf4dd.png',
        // preview_image:
        //   'https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20230919/1d3f7501cf3d7fb913ed338a68393a16.png',

        // 对应的实际中的标签尺寸，可以在纸卷筒的内侧查看
        label_width: 60,
        label_height: 40,
        // 实际需要减去尺子的宽度
        offset_x: 25,
        offset_y: 25,
      },
      customFixedVariable,

      // form: {},
      showLoading: false,
      saveLoading: false,
      rules: {
        print_design: [
          { required: true, message: '请上传标签设计', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) callback(new Error('请上传标签设计'))
              else if (!value.preview_image)
                callback(new Error('请上传标签设计'))
              else callback()
            },
            trigger: 'blur',
          },
        ],
        print_type: [
          { required: true, message: '请选择打印方式', trigger: 'blur' },
        ],
        print_machines: [
          { required: true, message: '请添加打印机', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value && value.length) {
                callback()
              } else {
                callback(new Error('请添加打印机'))
              }
            },
            trigger: 'blur',
          },
        ],
      },
      isEdit: true,
    }
  },
  computed: {
    form: {
      get() {
        console.log(this.dataForm)

        return this.dataForm
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
  },
  watch: {
    form(newValue) {
      if (newValue && !this.isDesignReady) {
        this.readyDesign()
      }
    }
  },
  created() {
    // this.id = this.$route.params.id
    // this.getData()
    if (this.form) {
      this.readyDesign()
    } else {
      // console.log('初始化失败') // form 可能 undefined
    }
  },
  methods: {
    readyDesign() {
      if (
          isArray(this.form.print_design) &&
          this.form.print_design.length === 0
      ) {
        // 还没有设计过活动标签（当作新增）
      } else {
        this.cert_design = Object.assign(
            {},
            // 默认一个打印纸尺寸
            this.cert_design,
            this.form.print_design || {} // 可能为 null
        )
      }
      this.isDesignReady = true
    },
    onDesignResize({ w, h }) {
      // 如：60*40
      this.cert_design.label_width = w
      this.cert_design.label_height = h
    },
    uploadFile,
    // 媒体库弹窗回调
    onSelect(data) {
      const item = data[0]
      if (this.$designCallback) {
        console.log(item.url, 'item.url')
        this.$designCallback(item.url)
        this.$designCallback = null //只触发一次，下次重新来
      }
    },
    handleSave() {
      this.form.print_design = this.cert_design
      this.$refs.detailForm.validate((valid) => {
        if (valid) {
          saveTagInfo({ ...this.form, id: this.$route.params.id }).then((res) => {
            this.$message.success(res.msg)
          })
        }
      })
    },
    getData() {
      this.showLoading = true
      TagInfo({ id: this.id })
        .then((res) => {
          this.showLoading = false
          this.form = res.data

          if (
            isArray(res.data.print_design) &&
            res.data.print_design.length === 0
          ) {
            // 还没有设计过活动标签（当作新增）
          } else {
            this.cert_design = Object.assign(
              {},
              // 默认一个打印纸尺寸
              this.cert_design,
              res.data.print_design || {} // 可能为 null
            )
          }
          this.isDesignReady = true
        })
        .catch(() => {
          this.showLoading = false
        })
    },
    onPrintMachinesUpdate(e) {
      this.form.print_machines = e
      this.$refs.detailForm.validate((valid) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  min-height: 400px;
  padding-top: $space;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(auto-fit, 40px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}

.el-popper.template-icon[x-placement^='bottom'] {
  margin-top: 0;
}
</style>
