<template>
  <el-dialog v-if="showAddPrinter" :title="editObj.id ? '编辑' : '新增'" :visible="showAddPrinter" width="504px"
    class="dialog-vertical" @close="closeAdd" close-on-press-escape append-to-body>
    <el-form ref="printers" class="medium-form" :model="form" size="medium" label-width="100px" :rules="form_rules"
      @submit.native.prevent>
      <el-form-item label="设备名称：" prop="name">
        <el-input v-model="form.name" placeholder="请填写" v-focus></el-input>
        <p class="info"><i class="el-icon-info" />前台展示，人员签到后将显示其签到打印的设备名称</p>
      </el-form-item>
      <el-form-item label="设备id：" prop="id">
        <el-input v-model="form.id" placeholder="请填写"></el-input>
        <p class="info"><i class="el-icon-info" />设备开机后，可在设备屏幕上查看</p>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="closeAdd">取消</el-button>
      <el-button size="small" type="primary" @click="handleAddCategory('printers')">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'add-printer',
  props: ['showAddPrinter', 'editObj', 'callback'],
  data() {
    return {
      form: {
        name: '',
        id: '',
      },
      form_rules: {
        name: [{ required: true, message: '请填写设备名称', trigger: 'blur' }],
        id: [{ required: true, message: '请填写设备id', trigger: 'blur' }],
      },
      iconList: [],
      isShowTemplateIcons: false,
    }
  },
  watch: {
    showAddPrinter:{
      handler(){
        this.form = JSON.parse(JSON.stringify(this.editObj))
        this.$refs.printers?.clearValidate()
      },
    }
  },
  created() {

  },
  methods: {
    chooseTemplateIcon(obj) {
      this.form.icon = obj
      this.isShowTemplateIcons = false
    },
    handleAddCategory(configForm) {
      this.$refs[configForm].validate((valid) => {
        if (valid) {
          this.callback(this.form)
        } else {
          return false
        }
      })
    },
    closeAdd() {
      this.form = {
        name: '',
        id: '',
      }
      this.$refs.printers.clearValidate()
      this.$emit('closeAddDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-grid {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(auto-fit, 40px);
  grid-gap: 10px;

  .icon-grid-item {
    padding: 8px;

    &.no-hover {
      padding: 0;
    }

    &:not(.no-hover):hover {
      background-color: $borderLighter;
      border-radius: 4px;
    }
  }

  ::v-deep.el-image__error {
    background-color: unset;
    font-size: 10px;
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
.el-popper.template-icon[x-placement^='bottom'] {
  margin-top: 0;
}
</style>
