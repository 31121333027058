<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="评论时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="评论内容：" prop="content">
          <el-input v-model="form.content" placeholder="请输入评论内容"></el-input>
        </el-form-item>
        <el-form-item label="评论者：" prop="user_name">
          <el-input v-model="form.user_name" placeholder="请输入用户名称"></el-input>
        </el-form-item>
        <el-form-item label="所属帖子：" prop="post_name">
          <el-input v-model="form.post_name" placeholder="请输入帖子内容"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="评论级别：" prop="comment_level">
          <el-select v-model="form.comment_level" placeholder="请选择评论级别">
            <el-option v-for="item in commentOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      commentOptions: [
        { name: "全部", id: -1 },
        { name: "一级评论", id: 0 },
        { name: "二级评论", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    // onExport() {
    //   const postData = { ...this.form, page: this.page };
    //   return topicsExport(postData)
    //     .then()
    //     .catch(() => {});
    // },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>