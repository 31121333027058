<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="150px"
      class="form small-form"
      :rules="rules"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">帮助设置</p>
        <div class="modular-content">
          <el-form-item label="秘书二维码：" prop="admin_user_qrcode">
            <SingleMediaWall
              v-model="ActivityForm.admin_user_qrcode"
              :width="200"
              :height="200"
              ratio="200;200"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">建议上传尺寸比例为 300*300</p>
            </SingleMediaWall>
          </el-form-item>
          <el-form-item label="问题答疑：" prop="questions">
            <ActivityQuestions
              v-if="ActivityForm.questions"
              :value="ActivityForm.questions"
            ></ActivityQuestions>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">活动群设置</p>
        <div class="modular-content">
          <el-form-item label="群聊二维码：" prop="chat_qrcode">
            <SingleMediaWall
              v-model="ActivityForm.chat_qrcode"
              :width="200"
              :height="200"
              ratio="200:200"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">建议上传尺寸比例为 300*300</p>
            </SingleMediaWall>
          </el-form-item>
          <el-form-item label="提醒时间：" prop="chat_qrcode_remind_time">
            <el-date-picker
              v-model="ActivityForm.chat_qrcode_remind_time"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <p class="info">
              <i class="el-icon-info"></i
              >因群聊二维码会过期，设置提醒时间来提醒管理员更换
            </p>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">备注&说明</p>
        <div class="modular-content">
          <el-form-item label="着装要求：" prop="dress">
            <el-input
              type="textarea"
              v-model="ActivityForm.dress"
              placeholder="请填写着装要求"
              rows="5"
              maxlength="200"
              show-word-limit
            ></el-input>
            <p class="info">
              <i class="el-icon-info"></i>活动状态未开始、报名中才显示
            </p>
          </el-form-item>
          <el-form-item label="备注说明：" prop="remark">
            <el-input
              type="textarea"
              v-model="ActivityForm.remark"
              placeholder="请填写备注说明"
              rows="5"
              maxlength="200"
              show-word-limit
            ></el-input>
            <p class="info">
              <i class="el-icon-info"></i>活动状态未开始、报名中才显示
            </p>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import ActivityQuestions from '@/modules/activity/components/ActivityList/ActivityQuestions'

export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  components: {
    SingleMediaWall,
    ActivityQuestions,
  },
  props: ['form', ''],
  data() {
    return {
      platform: 0,

      rules: {},
    }
  },
  watch: {},
  created() {},
  methods: {},
  computed: {
    ActivityForm: {
      get() {
        console.log(this.form)

        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
