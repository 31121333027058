/* 活动标签设计 */

const commonAttrs = {
  letterSpacing: 1,
  fontStyle: 'bold',
  fontFamily: 'SimSun',
  fill: '#000000',
  draggable: true,
  transformer: true,
  keepRatio: true,
  classify: 'fixedVariable',
  name: 'text',
  align: 'center',
}

function getSingleFixedVariable(
  id,
  title,
  text,
  x = 40,
  y = 40,
  fontSize = 40,
  visible = false
) {
  return {
    className: 'Text',
    attrs: {
      id,
      title,
      x,
      y,
      text,
      fontSize,
      myFontSize: fontSize,
      visible,
      ...commonAttrs,
    },
  }
}

// 固定的变量
export const customFixedVariable = [
  getSingleFixedVariable(
    'body_user_name',
    '会员名称',
    '李温江',
    175,
    100,
    100,
    true
  ),
  getSingleFixedVariable(
    'body_contact_info',
    '联系方式',
    '137xxxx8888',
    40,
    40,
    50
  ),
  getSingleFixedVariable(
    'body_company_name',
    '所属企业',
    '广州xx信息有限公司',
    135,
    325,
    40,
    true
  ),
  getSingleFixedVariable(
    'body_position_name',
    '会内职务',
    '荣誉主席',
    245,
    240,
    40,
    true
  ),
  getSingleFixedVariable('body_organization_name', '所属架构', '#所属架构#'),
  getSingleFixedVariable('body_seat_no', '座位号', '#座位号#'),
  getSingleFixedVariable('body_table_no', '用餐桌位号', '#用餐桌位号#'),
  getSingleFixedVariable('body_place_name', '所属会场', '#所属会场#'),
  getSingleFixedVariable('body_social_role', '社会身份', '#社会身份#'),
  getSingleFixedVariable('body_work_role', '在该企业职务', '#在该企业职务#'),
]

/**
 * 标签设计使用证书编辑组件，
 * 变量词为：
 * 1.会员名称。2.联系方式。3.所属企业。4.会内职务。5.所属架构。
 * 6.座位号。7.用餐桌位号。8.所属会场。9.社会身份。10.在该企业职务
 */
