<template>
  <div class="main-page">
    <!-- navList -->
    <div class="nav-list">
      <div
        class="nav-item"
        :class="[currentTab === item.tab ? 'active' : '']"
        v-for="item in navList"
        :key="item.tab"
        @click="changeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="nav-box">
      <el-form
        :model="ActivityForm"
        label-position="right"
        label-width="90px"
        :rules="rules"
        ref="ActivityForm"
        class="form small-form"
        @submit.native.prevent
      >
        <template v-if="currentTab === 0">
          <ActivityShare v-model="ActivityForm"></ActivityShare>
        </template>
        <template v-if="currentTab === 1">
          <ActivityConfig ></ActivityConfig>
        </template>
        <template v-if="currentTab === 2">
          <ActivityTag v-model="ActivityForm.tag_info"></ActivityTag>
        </template>
        <template v-if="currentTab === 3">
          <ActivityRank></ActivityRank>
        </template>
        <template v-if="currentTab === 4">
          <ActivityParking v-model="ActivityForm"></ActivityParking>
        </template>
        <template v-if="currentTab === 5">
          <ActivityHelp v-model="ActivityForm.help"></ActivityHelp>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ActivityShare from '@/modules/activity/components/activity-detail/detail-before/detail-share.vue'
import ActivityConfig from '@/modules/activity/components/activity-detail/detail-before/detail-config.vue'
import ActivityTag from '@/modules/activity/components/activity-detail/detail-before/detail-tag.vue'
import ActivityRank from '@/modules/activity/views/activity-list/ranking.vue'
import ActivityParking from '@/modules/activity/components/activity-detail/detail-before/detail-parking.vue'
import ActivityHelp from '@/modules/activity/components/activity-detail/detail-before/detail-help.vue'

export default {
  components: {
    SingleMediaWall,
    MediaWall,
    ActivityShare,
    ActivityConfig,
    ActivityTag,
    ActivityRank,
    ActivityParking,
    ActivityHelp
  },
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: ['form', 'saveDetail', 'saveLoading'],
  data() {
    return {
      rules: {},
      currentTab: 0,

      navList: [
        { name: '分享邀约', tab: 0 },
        { name: '签到码', tab: 1 },
        { name: '活动标签', tab: 2 },
        { name: '排位设置', tab: 3 },
        { name: '停车票', tab: 4 },
        { name: '帮助设置', tab: 5 },
      ],
    }
  },
  watch: {},
  created() {
    // 看看页面有没有传二级菜单的tab
    if (this.$route.query.secondTab && this.$route.query.activeTab === 'before') {
      this.currentTab = parseInt(this.$route.query.secondTab)
    }
  },
  methods: {
    changeTab(item) {
      this.currentTab = item.tab
    },
    // 保存
    formSubmit(formName) {
      let flag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          flag = false
        }
      })
      return flag
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },

    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped>
.main-page {
  .nav-list {
    margin-top: 16px;
    display: flex;
    align-items: center;
    // justify-content: center;
    .nav-item {
      padding: 8px 12px;
      border-radius: 4px;
      color: #b3b3b3;
      font-size: 13px;
      cursor: pointer;
      &.active {
        color: #1a75ff;
        background-color: rgba(#1a75ff, 0.05);
      }
    }
    .nav-item + .nav-item {
      margin-left: 20px;
    }
  }
  .nav-box {
    margin-top: 32px;
  }
  .live-box {
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;
    margin-top: 5px;
    width: 600px;

    .live-config {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 4px;

      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
