<template>
  <div class="main-page">
    <!-- navList -->
    <div class="nav-list">
      <div
        class="nav-item"
        :class="[currentTab === item.tab ? 'active' : '']"
        v-for="item in navList"
        :key="item.tab"
        @click="changeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="nav-box">
      <el-form
        :model="ActivityForm"
        label-position="right"
        label-width="90px"
        :rules="rules"
        ref="ActivityForm"
        class="form small-form"
        @submit.native.prevent
      >
        <template v-if="currentTab === 0">
          <el-form-item label="嘉宾信息：" label-width="0px" prop="guests">
            <ActivityGuests
              :guests="ActivityForm.guests"
              :guestList.sync="ActivityForm.guests"
            >
            </ActivityGuests>
          </el-form-item>
        </template>
        <template v-if="currentTab === 1">
          <el-form-item label="日程信息：" label-width="0px" prop="plans">
            <ActivityPlans
              :plans="ActivityForm.plans"
              @change="plansChange"
            ></ActivityPlans>
          </el-form-item>
        </template>
        <template v-if="currentTab === 2">
          <el-form-item label="合作单位：" label-width="0px" prop="units">
            <ActivityUnits
              :units="ActivityForm.units"
              @change="ActivityForm.units = $event"
            >
            </ActivityUnits>
          </el-form-item>
        </template>
        <template v-if="currentTab === 3">
          <el-form-item label-width="0px" prop="jump_config">
            <el-radio
              v-if="ActivityForm.type"
              v-model="ActivityForm.is_live"
              :label="1"
              >开启</el-radio
            >
            <el-radio
              v-if="ActivityForm.type"
              v-model="ActivityForm.is_live"
              :label="0"
              >关闭</el-radio
            >
            <template v-if="ActivityForm.is_live || !ActivityForm.type">
              <div class="live-box">
                <div class="live-config">
                  <el-form-item
                    label="直播方式："
                    label-width="100px"
                    prop="jump_type"
                  >
                    <el-radio-group
                      v-model="ActivityForm.jump_type"
                      @change="handleJumpTypeChange"
                    >
                      <el-radio
                        label="flow"
                        v-if="![''].includes(tenantVersion)"
                        >拉流地址</el-radio
                      >
                      <el-radio
                        label="wxlive"
                        v-if="![''].includes(tenantVersion)"
                        >微信小程序直播</el-radio
                      >
                      <el-radio label="wxvideo">微信视频号直播</el-radio>
                      <el-radio
                        label="path"
                        v-if="![''].includes(tenantVersion)"
                        >第三方微信小程序</el-radio
                      >
                      <el-radio
                        label="qqmeeting"
                        v-if="![''].includes(tenantVersion)"
                        >腾讯会议</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                  <el-form
                    size="small"
                    :model="ActivityForm.jump_config"
                    :rules="JumpConfigRules"
                    ref="JumpConfig"
                    label-position="right"
                    label-width="100px"
                  >
                    <el-form-item
                      label="拉流地址："
                      prop="url"
                      v-if="ActivityForm.jump_type === 'flow'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.url"
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      label="小程序名称："
                      prop="name"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.name"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="AppID："
                      prop="appid"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.appid"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="页面链接："
                      prop="path"
                      v-if="ActivityForm.jump_type === 'path'"
                    >
                      <el-input
                        type="text"
                        v-model="ActivityForm.jump_config.path"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="选择数据："
                      prop="roomId"
                      v-if="ActivityForm.jump_type === 'wxlive'"
                    >
                      <el-select
                        v-model="ActivityForm.jump_config.roomId"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="(item, index) in wxLiveOptions"
                          :key="index"
                          :label="item.name"
                          :value="item.room_id"
                        ></el-option>
                      </el-select>
                      <p class="info">
                        <i class="el-icon-info" />{{
                          liveTip[ActivityForm.jump_type]
                        }}
                      </p>
                    </el-form-item>
                    <!-- 腾讯会议 -->
                    <template v-if="ActivityForm.jump_type === 'qqmeeting'">
                      <el-form-item label="会议号：" prop="code">
                        <el-input
                          type="text"
                          v-model="ActivityForm.jump_config.code"
                        ></el-input>
                      </el-form-item>
                    </template>
                    <template v-if="ActivityForm.jump_type === 'wxvideo'">
                      <el-form-item label="视频号ID：" prop="wx_video_id">
                        <el-input
                          type="text"
                          v-model="ActivityForm.jump_config.wx_video_id"
                        ></el-input>
                        <p class="info">
                          <i
                            class="el-icon-info"
                          />小程序需要与视频号的主体相同或为<a
                            href="https://kf.qq.com/faq/190726rqmE7j190726BbeIFR.html"
                            >关联主体</a
                          >
                        </p>
                        <p class="info">
                          <i class="el-icon-info" />视频号id需通过<a
                            href="https://channels.weixin.qq.com/login"
                            target="_blank"
                            >视频号助手</a
                          >获取。
                        </p>
                        <p class="info">
                          <img
                            @click="
                              $previewImage(
                                require('@/modules/activity/assets/images/get-channel-id.png')
                              )
                            "
                            src="@/modules/activity/assets/images/get-channel-id.png"
                            style="max-width: 400px"
                          />
                        </p>
                      </el-form-item>
                    </template>
                  </el-form>

                  <el-form-item v-if="ActivityForm.is_live" label="观看权限：">
                    <el-checkbox
                      :true-label="1"
                      :false-label="0"
                      v-model="ActivityForm.live_for_visitor"
                      @change="handleLiveAccessChange"
                      >未登录用户可见
                    </el-checkbox>
                    <el-checkbox
                      :disabled="
                        !!ActivityForm.live_for_visitor && !isCycOrganization
                      "
                      :true-label="1"
                      :false-label="0"
                      v-model="ActivityForm.live_for_user"
                      >游客可见
                    </el-checkbox>
                  </el-form-item>
                </div>
              </div>
            </template>
          </el-form-item>
        </template>
        <template v-if="currentTab === 4">
          <Information></Information>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ActivityGuests from '@/modules/activity/components/ActivityList/ActivityGuests.vue'
import ActivityPlans from '@/modules/activity/components/ActivityList/ActivityPlans.vue'
import ActivityUnits from '../../components/ActivityList/ActivityUnits.vue'
import Information from '@/modules/activity/components/activity-detail/detail-info/information.vue'
import { wxLiveList } from '@/modules/activity/api/activity-list/detail.js'
export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: ['form', 'saveDetail', 'saveLoading'],
  data() {
    return {
      rules: {},
      currentTab: 0,
      wxLiveOptions: [],
      // 直播方式文案提示
      liveTip: {
        flow: '在界面上直接观看',
        path: '跳转至微信小程序界面观看',
        wxlive: '跳转至微信小程序直播间观看',
      },
      JumpConfigRules: {
        url: [{ required: true, message: '请输入地址链接', trigger: 'blur' }],
        path: [{ required: true, message: '请输入页面链接', trigger: 'blur' }],
        appid: [{ required: true, message: '请输入AppID', trigger: 'blur' }],
        roomId: [
          {
            required: true,
            message: '请选择微信小程序直播数据',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入腾讯会议号',
            trigger: 'blur',
          },
        ],
        name: [
          { required: true, message: '请输入小程序名称', trigger: 'blur' },
        ],
        // 视频号id
        wx_video_id: [
          { required: true, message: '请输入视频号ID', trigger: 'blur' },
        ],
      },
      navList: [
        { name: '嘉宾信息', tab: 0 },
        { name: '日程信息', tab: 1 },
        { name: '合作单位', tab: 2 },
        { name: '活动直播', tab: 3 },
        { name: '关联文章与话题', tab: 4 },
      ],
    }
  },
  watch: {},
  created() {
    if (['college'].includes(this.tenantVersion)) {
      this.ActivityForm.jump_type = 'wxvideo' // 只有一个选项
    }
    this.getWxLiveList()
    // 看看页面有没有传二级菜单的tab
    if (this.$route.query.secondTab && this.$route.query.activeTab === 'info') {
      this.currentTab = parseInt(this.$route.query.secondTab)
    }
  },
  methods: {
    /**
     * 直播观看权限
     * 游客可见，即非会员必须可见
     * @param {number} value  游客是否可见 选项：0-不可见，1-可见
     */
     handleLiveAccessChange(value) {
      if (value) this.ActivityForm.live_for_user = 1
    },
    // 获取微信小程序直播列表
    getWxLiveList() {
      wxLiveList()
        .then((res) => {
          this.wxLiveOptions = res.data
          console.log(res.data, 'res.data')
        })
        .catch((err) => {})
    },
    /**
     * 切换直播类型时，清除验证状态
     */
    handleJumpTypeChange() {
      this.$refs['JumpConfig'].clearValidate()
      this.$refs['ActivityForm'].clearValidate('jump_config')
    },
    // 活动议程数据变更
    plansChange(val) {
      this.ActivityForm.plans = val
    },
    changeTab(item) {
      this.currentTab = item.tab
    },
    // 保存
    formSubmit(formName) {
      let flag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          flag = false
        }
      })
      return flag
    },
  },
  computed: {
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  components: {
    SingleMediaWall,
    MediaWall,
    ActivityGuests,
    ActivityPlans,
    ActivityUnits,
    Information,
  },
}
</script>

<style lang="scss" scoped>
.main-page {
  .nav-list {
    margin-top: 16px;
    display: flex;
    align-items: center;
    // justify-content: center;
    .nav-item {
      padding: 8px 12px;
      border-radius: 4px;
      color: #b3b3b3;
      font-size: 13px;
      cursor: pointer;
      &.active {
        color: #1a75ff;
        background-color: rgba(#1a75ff, 0.05);
      }
    }
    .nav-item + .nav-item {
      margin-left: 20px;
    }
  }
  .nav-box {
    margin-top: 32px;
  }
  .live-box {
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;
    margin-top: 5px;
    width: 900px;

    .live-config {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 4px;

      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
