<template>
  <div class="main-page">
    <!-- navList -->
    <div class="nav-list">
      <div
        class="nav-item"
        :class="[currentTab === item.tab ? 'active' : '']"
        v-for="item in navList"
        :key="item.tab"
        @click="changeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="nav-box">
      <el-form
        :model="ActivityForm"
        label-position="right"
        label-width="90px"
        :rules="rules"
        ref="ActivityForm"
        class="form small-form"
        @submit.native.prevent
      >
        <template v-if="currentTab === 0">
          <div v-if="isActivityPhoto" class="modular">
            <p class="modular-title">相册关联</p>
            <div class="modular-content">
              <el-form-item label="选择相册：" prop="photo_activity_id">
                <!-- 相册选择 -->
                <SelectAlbum
                  v-model="ActivityForm.photo_activity_id"
                ></SelectAlbum>
              </el-form-item>
            </div>
          </div>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import SelectAlbum from '@/modules/activity/components/ActivityList/SelectAlbum'

export default {
  components: {
    SingleMediaWall,
    MediaWall,
    SelectAlbum,
  },
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: ['form', 'saveDetail', 'saveLoading'],
  data() {
    return {
      rules: {},
      currentTab: 0,
      navList: [{ name: '相册管理', tab: 0 }],
    }
  },
  watch: {},
  created() {
    // 看看页面有没有传二级菜单的tab
    if (this.$route.query.secondTab && this.$route.query.activeTab === 'after') {
      this.currentTab = parseInt(this.$route.query.secondTab)
    }
  },
  methods: {
    changeTab(item) {
      this.currentTab = item.tab
    },
    // 保存
    formSubmit(formName) {
      let flag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          flag = false
        }
      })
      return flag
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    isActivityPhoto() {
      // activity_photo
      return this.$store.getters.tenant.features.includes('activity_photo')
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped>
.modular-title {
  padding-left: 8px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  line-height: 16px;
  border-left: 3px solid $primary;
  margin-bottom: 32px;
}

.modular-content {
  margin-left: 11px;
}
.main-page {
  .nav-list {
    margin-top: 16px;
    display: flex;
    align-items: center;
    // justify-content: center;
    .nav-item {
      padding: 8px 12px;
      border-radius: 4px;
      color: #b3b3b3;
      font-size: 13px;
      cursor: pointer;
      &.active {
        color: #1a75ff;
        background-color: rgba(#1a75ff, 0.05);
      }
    }
    .nav-item + .nav-item {
      margin-left: 20px;
    }
  }
  .nav-box {
    margin-top: 32px;
  }
  .live-box {
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;
    margin-top: 5px;
    width: 600px;

    .live-config {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 4px;

      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
