<template>
  <div
    class="poster-box"
    ref="posterBox"
    :style="{
      transform: `scale(${scale})`,
    }"
  >
    <!-- 背景 -->
    <template v-if="platform">
      <img :src="posterList[form.h5_poster_template]" class="poster-bg" />
    </template>
    <template v-else>
      <img :src="posterList[form.mp_poster_template]" class="poster-bg" />
    </template>
    <!-- logo -->
    <img v-if="platform" :src="form.h5_icon" class="logo" />
    <img v-else :src="form.mp_icon" class="logo" />
    <!-- 内容 -->
    <div class="poster-content">
      <img :src="form.cover" class="cover" />
      <div
        class="cover-title"
        :style="{
          color: textColor,
        }"
      >
        {{ form.name }}
      </div>
      <div
        class="address-label"
        :style="{
          color: textColor,
        }"
      >
        <i
          :style="{
            marginRight: '6px',
            color: textColor,
          }"
          class="el-icon-location"
        ></i
        >举办地址：
      </div>
      <div
        class="address"
        :style="{
          color: textColor,
        }"
      >
        {{ form.address_name }}
      </div>
      <div class="line"></div>
      <!-- 时间 -->
      <div
        class="time-box"
        :style="{
          color: textColor,
        }"
      >
        <div class="date-list">
          <div class="date-start">
            {{ timestampToDate(form.activity_start_time) }}
          </div>
          <div class="date-end">
            {{ timestampToDate(form.activity_end_time) }}
          </div>
        </div>
        <div class="time-list">
          <div class="time-item-left">
            <div class="time">
              {{ timestampToTime(form.activity_start_time) }}
            </div>
          </div>
          <div class="activity-type"></div>
          <div class="time-item-right">
            <div class="time">
              {{ timestampToTime(form.activity_end_time) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- qrcode -->
    <template v-if="platform">
      <!-- H5, -->
      <img
        v-if="form.h5_poster_show_qrcode"
        :src="form.h5_share_qrcode"
        class="qrcode"
      />
    </template>
    <template v-else>
      <!-- 小程序 -->
      <img
        v-if="form.mp_poster_show_qrcode"
        :src="form.mp_share_qrcode"
        class="qrcode-weixin"
      />
    </template>
  </div>
</template>

<script>
import { getPosterBgs } from '@/modules/activity/api/activity-detail/detail.js'

export default {
  name: 'poster-dialog',
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    scale: {
      // 设计稿尺寸 540 * 900  根据传入的比例缩放
      type: String,
      default: '1',
    },
    //  小程序：0, H5： 1
    platform: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false, // 加载
      posterList: [],
      baseImgUrl: '',
    }
  },
  mounted() {
    this.getPoster()
  },
  destroyed() {},
  methods: {
    // 获取海报
    getPoster() {
      this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
      let arr = []
      // 获取海报
      for (let i = 0; i < 6; i++) {
        let str = `${this.baseImgUrl}/activity/poster_bg_${i}.jpg`
        arr.push(str)
      }
      this.posterList = arr
    },
    timestampToDate(timestamp) {
      console.log(timestamp)
      const date = new Date(timestamp * 1000)

      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }

      return month + '月' + day + '日'
    },
    timestampToTime(timestamp) {
      const date = new Date(timestamp * 1000)

      let hour = date.getHours()

      let minute = date.getMinutes()

      if (hour < 10) {
        hour = '0' + hour
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      return hour + ':' + minute
    },
  },
  computed: {
    textColor() {
      if (this.platform) {
        //h5
        if (this.form.h5_poster_template === 3) {
          return '#405E08'
        }
        return '#fff'
      } else {
        if (this.form.mp_poster_template === 3) {
          return '#405E08'
        }
        return '#fff'
      }
    },
  },
  components: {},
}
</script>

<style lang="scss" scoped>
.poster-box {
  width: 540px;
  height: 960px;
  position: relative;
  transform-origin: left top;
  .poster-bg {
    width: 100%;
    height: 100%;
  }
  .logo {
    width: auto;
    max-width: 500px;
    height: 58px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .poster-content {
    width: 481px;
    height: 615px;
    position: absolute;
    top: 119px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 32px;
    // border: 1px solid #fff;
    // background: rgba(#fff, 0.3);
    // backdrop-filter: blur(20px);
    padding: 20px;
    .cover {
      width: 441px;
      height: 235px;
      border-radius: 24px;
    }
    .cover-title {
      margin-top: 17px;
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      min-height: 96px;
      color: #fff;
      margin-bottom: 12px;
      @include clamp(2);
    }
    .address-label {
      font-size: 18px;
      color: rgba(#fff, 0.8);
    }
    .address {
      margin-top: 8px;
      font-size: 18px;
      color: rgba(#fff, 0.8);
    }
    .line {
      margin: 22px auto 0;
      width: 425px;
      height: 1px;
      // border-top: 1px dashed transparent;
    }
    .time-box {
      margin-top: 26px;
      .date-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date-start {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          // color: #fff;
        }
        .date-end {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 36px;
          white-space: nowrap;

          // color: #fff;
        }
      }
      .time-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .time-item-left {
          .time {
            // color: #fff;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 72px;
          }
        }
        .time-item-right {
          .date {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 36px;
            // color: #fff;
            text-align: right;
          }
          .time {
            // color: #fff;
            font-size: 48px;
            font-style: normal;
            font-weight: 500;
            line-height: 72px;
          }
        }
      }
    }
  }
  .qrcode {
    width: 156px;
    height: 156px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
  }
  .qrcode-weixin {
    width: 164px;
    height: 164px;
    border-radius: 50%;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    background-color: #fff;
  }
}
</style>
