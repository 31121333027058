import api from "@/base/utils/request";

export const TagInfo = data => {
  return api({
    url: "/admin/cyc_activity/activity/tagInfo",
    method: "post",
    data
  });
};
export const saveTagInfo = data => {
    return api({
      url: "/admin/cyc_activity/activity/saveTagInfo",
      method: "post",
      data
    });
  };
  export const QrCode = data => {
    return api({
      url: "/admin/cyc_activity/activity_config/QrCode",
      method: "post",
      data
    });
  };
 