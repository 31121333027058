<template>
  <div class="confirm-content">
    <el-button type="primary" size="small" style="margin-bottom: 20px;" @click="Add">添加</el-button>
    <el-table :data="myList" class="thead-light" stripe style="width:600px">
      <!-- 操作 -->
      <el-table-column label="操作" :width="200" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="Download(scope)">下载二维码</el-button>
          <el-button type="text" size="small" @click="Edit(scope)">编辑</el-button>
          <el-button type="text" size="small" @click="Delete(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
        </el-table-column>
      </template>
    </el-table>
    <AddPrinter :showAddPrinter="showAddPrinter" :callback="callback" :editObj="form"
      @closeAddDialog="showAddPrinter = false">

    </AddPrinter>
    <Config :showCode="showCode" @closeCode="()=>showCode = false" :id="id"></Config>
  </div>
</template>

<script>
import AddPrinter from './AddPrinter.vue'
import Config from './Config.vue'
export default {
  components: {
    AddPrinter,Config
  },
  props: {
    list: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      thead: [
        { label: "打印机名称", prop: "name", minWidth: 100 },
        { label: "设备id", prop: "id", minWidth: 100 },
      ],
      showAddPrinter: false,
      form: {
      },
      id:'',
      callback: () => { },
      showCode: false,
    };
  },
  methods: {
    // 点击添加
    Add() {
      this.showAddPrinter = true
      this.form = {}
      this.callback = (form) => {
        if(this.list.find(t=>t.name === form.name || t.id === form.id)){
          this.$message.error("不能有相同名称或id的设备");
          return
        }
        this.showAddPrinter = false
        this.myList.push(form)
      }
    },
    // 点击移除
    Delete(i) {
      this.myList.splice(i, 1);
    },
    Edit({row,$index}) {
      this.callback = (form) => {
        if(this.list.find((t,i)=>(t.name === form.name || t.id === form.id)&&i !==$index)){
          this.$message.error("不能有相同名称或id的设备");
          return
        }
        this.showAddPrinter = false
  
        this.myList.splice($index, 1, form)
        console.log( this.myList);
      }
       

      this.form = row
      this.showAddPrinter = true
      console.log(this.form );
    },
    Download({row}){
      this.id = row.id
      this.showCode = true
    }
  },
  watch: {
    myList(){
      this.$emit("setList", this.myList);
    }
  },
  computed: {
    myList: {
      get() {
        return this.list || [];
      },
      set(val) {
      
     
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>
