import { render, staticRenderFns } from "./detail-during.vue?vue&type=template&id=4402a895&scoped=true&"
import script from "./detail-during.vue?vue&type=script&lang=js&"
export * from "./detail-during.vue?vue&type=script&lang=js&"
import style0 from "./detail-during.vue?vue&type=style&index=0&id=4402a895&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4402a895",
  null
  
)

export default component.exports