<template>
  <el-dialog
    class="dialog-vertical"
    title="编辑海报"
    width="900px"
    :visible.sync="openDialog"
    close-on-click-modal
    close-on-press-escape
    append-to-body
    @open="open"
    v-if="form"
  >
    <div class="content-dialog">
      <div class="poster">
        <template v-if="platform">
          <!-- 预览 -->
          <PosterPreview
            v-if="!DialogForm.h5_poster_is_custom"
            scale="0.52"
            :platform="platform"
            v-model="DialogForm"
          ></PosterPreview>
          <img
            v-else-if="DialogForm.h5_poster_is_custom"
            :style="{ width: '100%', height: '100%' }"
            :src="DialogForm.h5_poster_bg"
          />
        </template>
        <template v-else>
          <!-- 预览 -->
          <PosterPreview
            v-if="!DialogForm.mp_poster_is_custom"
            scale="0.52"
            :platform="platform"
            v-model="DialogForm"
          ></PosterPreview>
          <img
            v-else-if="DialogForm.mp_poster_bg"
            :style="{ width: '100%', height: '100%' }"
            :src="DialogForm.mp_poster_bg"
          />
        </template>
      </div>
      <div class="right">
        <el-scrollbar>
          <template v-if="platform">
            <div class="setting-item">
              <div class="title">海报类型：</div>
              <el-radio-group v-model="DialogForm.h5_poster_is_custom">
                <el-radio :label="1">自定义</el-radio>
                <el-radio :label="0">模版</el-radio>
              </el-radio-group>
            </div>
            <template v-if="DialogForm.h5_poster_is_custom">
              <div class="setting-item">
                <div class="title">自定义海报：</div>
                <SingleMediaWall
                  v-model="DialogForm.h5_poster_bg"
                  :width="194"
                  :height="135"
                  :isButtonStyle="true"
                  :ratio="[
                    {
                      ratio: `${686 / 2 * 3}:${368 / 2 * 3}`,
                      desc: `模板一（竖向列表，大图，一行一个）尺寸${686 / 2 * 3}*${368 / 2 * 3}`,
                    },
                  ]"
                  :show-cut="true"
                >
                </SingleMediaWall>
              </div>
            </template>
            <template v-else>
              <div class="setting-item">
                <div class="title">二维码显示：</div>
                <el-radio-group v-model="DialogForm.h5_poster_show_qrcode">
                  <el-radio :label="1">显示</el-radio>
                  <el-radio :label="0">不显示</el-radio>
                </el-radio-group>
              </div>

              <div class="poster-list">
                <img
                  :src="item"
                  class="poster-item"
                  :class="[i === DialogForm.h5_poster_template ? 'active' : '']"
                  v-for="(item, i) in posterList"
                  @click="DialogForm.h5_poster_template = i"
                  :key="i"
                />
              </div>
            </template>
          </template>
          <template v-else>
            <div class="setting-item">
              <div class="title">海报类型：</div>
              <el-radio-group v-model="DialogForm.mp_poster_is_custom">
                <el-radio :label="1">自定义</el-radio>
                <el-radio :label="0">模版</el-radio>
              </el-radio-group>
            </div>
            <template v-if="DialogForm.mp_poster_is_custom">
              <div class="setting-item">
                <div class="title">自定义海报：</div>
                <SingleMediaWall
                  v-model="DialogForm.mp_poster_bg"
                  :width="194"
                  :height="135"
                  :isButtonStyle="true"
                  :ratio="[
                    {
                      ratio: `${686 / 2 * 3}:${368 / 2 * 3}`,
                      desc: `模板一（竖向列表，大图，一行一个）尺寸${686 / 2 * 3}*${368 / 2 * 3}`,
                    },
                  ]"
                  :show-cut="true"
                >
                </SingleMediaWall>
              </div>
            </template>
            <template v-else>
              <div class="setting-item">
                <div class="title">二维码显示：</div>
                <el-radio-group v-model="DialogForm.mp_poster_show_qrcode">
                  <el-radio :label="1">显示</el-radio>
                  <el-radio :label="0">不显示</el-radio>
                </el-radio-group>
              </div>

              <div class="poster-list">
                <img
                  :src="item"
                  class="poster-item"
                  :class="[i === DialogForm.mp_poster_template ? 'active' : '']"
                  v-for="(item, i) in posterList2"
                  @click="DialogForm.mp_poster_template = i"
                  :key="i"
                />
              </div>
            </template>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getPosterBgs } from '@/modules/activity/api/activity-detail/detail.js'
import PosterPreview from '@/modules/activity/components/activity-detail/detail-before/poster-preview.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'

export default {
  name: 'poster-dialog',
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    //  小程序：0, H5： 1
    platform: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false, // 加载

      activityDialog: {
        show: false,
        data: null,
      },
      posterList: [],
      posterList2: [],
      DialogForm: {},
      baseImgUrl: '',
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    open() {
      this.DialogForm = JSON.parse(JSON.stringify(this.$props.form))

      this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
      let arr = []
      // 获取海报
      for (let i = 0; i < 6; i++) {
        let str = `${this.baseImgUrl}/activity/poster_bg_${i}.jpg`
        arr.push(str)
      }
      this.posterList = arr
      let arr2 = []
      // 获取海报
      for (let i = 0; i < 6; i++) {
        let str = `${this.baseImgUrl}/activity/poster_bg_0${i + 1}.jpg`
        arr2.push(str)
      }
      this.posterList2 = arr2
    },
    submit() {
      console.log('submit')
      console.log(this.DialogForm)
      this.$emit('updateForm', this.DialogForm)
      this.openDialog = false
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('close', val)
      },
    },
  },
  components: {
    PosterPreview,
    SingleMediaWall,
  },
}
</script>

<style lang="scss" scoped>
.content-dialog {
  display: flex;
  height: 550px;
  .poster {
    width: 282px;
    height: 500px;
    // background-color: #cfcfcf;
  }
  .right {
    margin-left: 20px;
    .setting-item {
      .title {
        color: #25282d;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 8px;
      }
      .tips {
        margin-bottom: 16px;
      }
      .setting-qrcode {
        width: 130px;
        height: 130px;
      }
    }
    .setting-item + .setting-item {
      margin-top: 32px;
    }
    .poster-list {
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 12px;
      .poster-item {
        width: 130px;
        height: 233px;
        background-color: #cfcfcf;
        border: 2px solid #fff;

        &.active {
          border: 2px solid #2074f7;
        }
      }
    }
  }
}
</style>
