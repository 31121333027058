<template>
  <div class="main-page">
    <!-- navList -->
    <div class="nav-list">
      <div
        class="nav-item"
        :class="[currentTab === item.tab ? 'active' : '']"
        v-for="item in navList"
        :key="item.tab"
        @click="changeTab(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="nav-box">
      <el-form
        :model="ActivityForm"
        label-position="right"
        label-width="90px"
        :rules="rules"
        ref="ActivityForm"
        class="form small-form"
        @submit.native.prevent
      >
        <template v-if="currentTab === 0">
          <ImageText></ImageText>
        </template>
        <template v-if="currentTab === 1 && topic_id">
          <p class="modular-title">互动设置</p>
          <div class="modular-content">
            <el-form-item label="发帖互动：" prop="circle_post_enable">
              <el-radio-group v-model="ActivityForm.circle_post_enable">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <PostList :topic_id="topic_id"></PostList>
        </template>
        <template v-if="currentTab === 2 && topic_id">
          <CommentList :topic_id="topic_id"></CommentList>
        </template>
      </el-form>
    </div>
  </div>
</template>

<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ImageText from '@/modules/activity/components/activity-detail/detail-during/image-text.vue'
import PostList from '@/modules/activity/components/activity-detail/detail-during/post-list.vue'
import CommentList from '@/modules/activity/components/activity-detail/detail-during/comment-list.vue'
import { getCircleTopic } from '@/modules/activity/api/activity-post/list.js'

export default {
  components: {
    SingleMediaWall,
    MediaWall,
    ImageText,
    PostList,
    CommentList,
  },
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  props: ['form', 'saveDetail', 'saveLoading'],
  data() {
    return {
      rules: {},
      currentTab: 0,
      topic_id: '',
      navList: [
        { name: '图文管理', tab: 0 },
        { name: '发帖管理', tab: 1 },
        { name: '评论管理', tab: 2 },
      ],
    }
  },
  watch: {},
  created() {
    this.getTopic()
    // 看看页面有没有传二级菜单的tab
    if (this.$route.query.secondTab && this.$route.query.activeTab === 'during') {
      this.currentTab = parseInt(this.$route.query.secondTab)
    }
  },
  methods: {
    async getTopic() {
      let res = await getCircleTopic({ activity_id: this.ActivityForm.id })

      if (res.data) this.topic_id = res.data.id
    },
    changeTab(item) {
      this.currentTab = item.tab
    },
    // 保存
    formSubmit(formName) {
      let flag = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = true
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          flag = false
        }
      })
      return flag
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
}
</script>

<style lang="scss" scoped>
 .modular-title {
    padding-left: 8px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    line-height: 16px;
    border-left: 3px solid $primary;
    margin-bottom: 32px;
  }

  .modular-content {
    margin-left: 11px;
  }
.main-page {
  .nav-list {
    margin-top: 16px;
    display: flex;
    align-items: center;
    // justify-content: center;
    .nav-item {
      padding: 8px 12px;
      border-radius: 4px;
      color: #b3b3b3;
      font-size: 13px;
      cursor: pointer;
      &.active {
        color: #1a75ff;
        background-color: rgba(#1a75ff, 0.05);
      }
    }
    .nav-item + .nav-item {
      margin-left: 20px;
    }
  }
  .nav-box {
    margin-top: 32px;
  }
  .live-box {
    padding: 16px;
    background-color: #f5f7fa;
    border-radius: 4px;
    margin-top: 5px;
    width: 600px;

    .live-config {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 4px;

      .el-form-item {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
