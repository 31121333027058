<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="0px"
      class="form small-form"
      :rules="rules"
      @submit.native.prevent
    >
      <div class="modular">
        <p class="modular-title">停车票上传</p>
        <div class="modular-content">
          <el-form-item label=""  prop="parking_tickets">
            <ParkingTickets
              v-model="ActivityForm.parking_tickets"
            ></ParkingTickets>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import ParkingTickets from "@/modules/activity/components/ActivityList/ParkingTickets.vue";

export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  components: {
    SingleMediaWall,
    ParkingTickets
  },
  props: ['form', ''],
  data() {
    return {
      platform: 0,

      rules: {},
    }
  },
  watch: {},
  created() {},
  methods: {},
  computed: {
    ActivityForm: {
      get() {
        console.log(this.form)

        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>

</style>
