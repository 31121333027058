<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="150px"
      class="form small-form"
      :rules="rules"
      v-if="ActivityForm"
      @submit.native.prevent
    >
      <div class="modular">
        <!-- <p class="modular-title">报名信息</p> -->
        <div class="modular-content">
          <el-form-item label="报名时间：" prop="activityJoinTime">
            <el-date-picker
              v-model="ActivityForm.activityJoinTime"
              type="datetimerange"
              range-separator="至"
              :picker-options="pickerJoinOptions"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              @change="handleSelectJoinTime"
              time-arrow-control
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报名人数：" prop="join_limit">
            <el-input-number
              placeholder="请填写报名人数"
              v-model.number="ActivityForm.join_limit"
              :step="1"
            ></el-input-number>
          </el-form-item>

          <el-form-item class="join-type" label="报名类型：" prop="join_type">
            <el-radio-group v-model="ActivityForm.join_type">
              <el-radio :label="0">免费报名</el-radio>
              <el-radio :label="1">付费报名</el-radio>
              <el-radio :label="2">保证金报名</el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- 付费报名 -->
          <template v-if="ActivityForm.join_type === 1">
            <el-form-item label="付费价格：" prop="join_price">
              <el-input
                style="width: 190px"
                placeholder="请输入"
                v-model.number="ActivityForm.join_price"
                :disabled="ActivityForm.join_type !== 1"
              >
                <template slot="append">
                  <el-select
                    style="width: 90px"
                    v-model="ActivityForm.pay_type"
                  >
                    <el-option
                      v-for="item in payTypeOptions"
                      :key="item.id"
                      :disabled="item.label === '积分'"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-input>
            </el-form-item>
            <!-- 早鸟价格 -->
            <el-form-item label="早鸟价格：" prop="early_price_enable">
              <el-switch
                :active-value="1"
                :inactive-value="0"
                v-model="ActivityForm.early_price_enable"
              >
              </el-switch>
              <!-- 开启早鸟价才显示 -->
              <template v-if="ActivityForm.early_price_enable">
                <el-form-item prop="early_price" style="margin-top: 10px">
                  <el-input
                    style="width: 190px"
                    placeholder="请输入"
                    v-model.number="ActivityForm.early_price"
                    :disabled="ActivityForm.join_type !== 1"
                  >
                    <template slot="append">
                      <el-select
                        style="width: 90px"
                        v-model="ActivityForm.pay_type"
                      >
                        <el-option
                          v-for="item in payTypeOptions"
                          :key="item.id"
                          :disabled="true"
                          :label="item.label"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form
                  label-width="100px"
                  size="small"
                  class="form small-form"
                  label-position="left"
                  :rules="rules"
                  :model="ActivityForm"
                >
                  <el-form-item label="时间设置：" style="margin-top: 10px">
                    <el-date-picker
                      v-model="ActivityForm.early_price_time"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      value-format="timestamp"
                      format="yyyy-MM-dd HH:mm"
                      :picker-options="pickerEarlyOptions"
                      @change="handleSelectEarlyPriceTime"
                      time-arrow-control
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
              </template>
            </el-form-item>
            <el-form-item
              v-if="isCycOrganization"
              label="会员权益："
              prop="join_price"
            >
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.pay_free_for_cycuser"
                >会员无需付费，游客需付费</el-checkbox
              >
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.pay_free_for_gov_user"
                >协会互联会员无需付费</el-checkbox
              >
            </el-form-item>
            <el-form-item
              v-if="isCycOrganization"
              label="职务报名价格："
              prop="pay_price_for_cycuser"
            >
              <el-switch
                :active-value="1"
                :inactive-value="0"
                v-model="ActivityForm.pay_price_for_cycuser"
              >
              </el-switch>
              <p class="info">
                <i class="el-icon-info"></i>开启后可设置不同职务的价格
              </p>
              <template v-if="ActivityForm.pay_price_for_cycuser">
                <activity-price-stage
                  style="width: 700px"
                  :stages="ActivityForm.pay_cycuser_price_config"
                  :positions="selecPricePositions"
                >
                </activity-price-stage>
              </template>
            </el-form-item>
          </template>

          <!-- 保证金报名 -->
          <template v-if="ActivityForm.join_type === 2">
            <el-form-item label="保证金报名：" prop="seat_price">
              <el-input
                style="width: 190px"
                placeholder="请输入"
                v-model.number="ActivityForm.seat_price"
                :disabled="ActivityForm.join_type !== 2"
              >
                <template slot="append">
                  <el-select
                    v-model="ActivityForm.pay_type"
                    style="width: 90px"
                  >
                    <el-option
                      v-for="item in payTypeOptions"
                      :disabled="item.label === '积分'"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-input>
              <el-form-item
                v-show="ActivityForm.join_type === 2"
                label="保证金规则"
                style="margin-top: 20px"
              >
                <div style="margin-left: 20px; margin-top: 10px">
                  <el-radio-group
                    v-model="ActivityForm.seat_rule"
                    style="max-width: 400px"
                  >
                    <el-radio :label="0"
                      >取消报名时不返还保证金；签到后返还保证金</el-radio
                    >
                    <el-radio :label="1" style="margin-top: 10px"
                      >取消报名时按服务费扣除规则执行；签到后返还保证金</el-radio
                    >
                  </el-radio-group>
                  <p class="info">
                    <i class="el-icon-info" />
                    <router-link :to="{ name: 'cycBasisConfig' }">
                      <el-button type="text" style="padding: 0"
                        >前往配置服务费扣除规则</el-button
                      >
                    </router-link>
                  </p>
                </div>
              </el-form-item>
            </el-form-item>
          </template>

          <!-- <el-form-item v-if="ActivityForm.type" label="添加已报名会员：" prop="joined_member">
        <el-button plain @click="openMemberSelector = true">添加已报名会员
        </el-button>
      </el-form-item> -->
          <el-form-item
            v-if="isCycOrganization"
            label="报名要求："
            prop="can_all_cycuser_join"
          >
            <el-radio-group v-model="ActivityForm.can_all_cycuser_join">
              <el-radio :label="1">全员报名</el-radio>
              <el-radio :label="0">限制报名</el-radio>
            </el-radio-group>
            <p class="info">
              <i class="el-icon-info"></i
              >{{
                tenantVersion === 'school'
                  ? '开启后允许全部校友报名（包含后续认证的校友）'
                  : '开启后将允许全部会员报名（包括后续新增的组织架构、会内职务的会员）'
              }}
            </p>
            <el-form
              label-width="100px"
              size="small"
              class="form small-form"
              label-position="left"
              :rules="rules"
              :model="ActivityForm"
            >
              <el-form-item
                v-if="ActivityForm.can_all_cycuser_join === 0"
                style="margin: 10px 0"
                label="选择职务："
                prop="join_positions"
              >
                <el-cascader
                  style="width: 295px"
                  v-if="positionOptions"
                  v-model="ActivityForm.join_positions"
                  :options="positionOptions"
                  :props="props"
                  collapse-tags
                  clearable
                  @change="positionSelected"
                >
                </el-cascader>
                <p class="tips">提示：选中的职务可以报名</p>
              </el-form-item>
            </el-form>
          </el-form-item>
          <el-form-item label="游客报名设置：">
            <div class="flex-col" style="max-width: 350px">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.can_visitor_join"
                :disabled="!isCycOrganization"
              >
                {{ '游客可报名' | saasVersionKeyword }}
              </el-checkbox>
              <div v-if="ActivityForm.can_visitor_join">
                <el-form-item
                  style="margin-left: 24px"
                  :label="handleSaaSVersionKeyword('游客报名审核')"
                  label-width="95px"
                >
                  <el-radio
                    style="margin-left: 20px"
                    v-model="ActivityForm.check_visitor_join"
                    :label="0"
                    >无需审核
                  </el-radio>
                  <el-radio v-model="ActivityForm.check_visitor_join" :label="1"
                    >需人工审核</el-radio
                  >
                </el-form-item>
              </div>
           
            </div>
          </el-form-item>
          <el-form-item label="资料完善设置：">
            <el-checkbox
              :true-label="1"
              :false-label="0"
              v-model="ActivityForm.is_info_completed"
            >
              游客需完善姓名，会员需完善所有必填项方可报名
            </el-checkbox>

            <!-- <p class="info" style="margin-left: 25px; margin-top: 0">
              <template v-if="isCycOrganization">
                <i class="el-icon el-icon-info" />
                {{ handleSaaSVersionKeyword('非会员不受该资料限制') }}
                <br />
              </template>
                               <i class="el-icon el-icon-info" /> 游客需填写姓名才可报名
            </p> -->
          </el-form-item>
          <!-- <el-form-item label="位置限制：" prop="address_limit">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="ActivityForm.address_limit"
            >
            </el-switch>
            <p class="info">
              <i class="el-icon-info"></i>开启后，只允许已选择地区的会员报名活动
            </p>
          </el-form-item> -->
          <!-- <el-form-item v-if="ActivityForm.address_limit" prop="address_limit">
            <el-cascader
              :options="CityDataList"
              :props="optionProp"
              v-model="ActivityForm.limited_addresses"
              collapse-tags
              clearable
              placeholder="请选择地理位置"
            />
          </el-form-item>
          <el-form-item label="其他限制：">
            <div class="flex-col">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.is_pay_dues_join"
              >
                未缴纳会费不可参与报名
              </el-checkbox>
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.can_visitor_join"
              >
                {{ "非会员用户可报名" | saasVersionKeyword }}
              </el-checkbox>
              <div v-if="ActivityForm.can_visitor_join">
                <el-form-item
                  style="margin-bottom: 0"
                  :label="handleSaaSVersionKeyword('非会员报名审核')"
                  label-width="95px"
                >
                  <el-radio
                    style="margin-left: 20px"
                    v-model="ActivityForm.check_visitor_join"
                    :label="0"
                    >无需审核
                  </el-radio>
                  <el-radio v-model="ActivityForm.check_visitor_join" :label="1"
                    >需人工审核</el-radio
                  >
                </el-form-item>
              </div>
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.is_info_completed"
              >
                非会员需要填写姓名才可报名
              </el-checkbox>
              <p class="info" style="margin-left: 25px; margin-top: 0">
                <i class="el-icon el-icon-info" />
                {{ handleSaaSVersionKeyword("非会员不受该资料限制") }}
                <br />
                <i class="el-icon el-icon-info" /> 游客需填写姓名才可报名
              </p>
            </div>
          </el-form-item> -->
          <el-form-item
            v-if="
              ActivityForm.join_start_time &&
              ActivityForm.can_all_cycuser_join === 0 &&
              isCycOrganization
            "
            label="阶段性报名："
            prop="stage_json"
          >
            <el-radio v-model="ActivityForm.is_stage" :label="1">开启</el-radio>
            <el-radio v-model="ActivityForm.is_stage" :label="0">关闭</el-radio>
            <!-- 阶段性报名列表 -->
            <div v-if="ActivityForm.is_stage">
              <p class="info" style="margin-bottom: 10px">
                <i
                  class="el-icon-info"
                />提示：1、未设置的职务，将在阶段性报名结束后允许报名；2、非会员用户将在阶段性报名结束后允许报名
              </p>
              <activity-stage
                style="width: 100%"
                :stages="ActivityForm.stage_json"
                :positions="selectablePositions"
                :ActivityTime="ActivityForm.activityJoinTime"
              >
              </activity-stage>
            </div>
          </el-form-item>
          <!-- 位置限制 -->
          <el-form-item label="位置限制：" prop="address_limit">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="ActivityForm.address_limit"
            >
            </el-switch>
            <p class="info">
              <i class="el-icon-info"></i>开启后，只允许已选择地区的用户报名活动
            </p>
          </el-form-item>
          <el-form-item v-if="ActivityForm.address_limit" prop="address_limit">
            <el-cascader
              :options="CityDataList"
              :props="optionProp"
              v-model="ActivityForm.limited_addresses"
              collapse-tags
              clearable
              placeholder="请选择地理位置"
            />
          </el-form-item>
          <!-- 协会互联会员报名 -->
          <el-form-item label="协会互联会员报名设置：" v-if="tenantGov">
            <el-radio v-model="ActivityForm.gov_join_limit" :label="1"
              >允许报名
            </el-radio>
            <el-radio v-model="ActivityForm.gov_join_limit" :label="0"
              >不允许报名</el-radio
            >
          </el-form-item>
          <el-form-item v-if="isCycOrganization" label="其他限制：">
            <div class="flex-col" style="max-width: 350px">
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="ActivityForm.is_pay_dues_join"
              >
                <span> 未缴纳会费不可参与报名 </span>
              </el-checkbox>
            </div>
          </el-form-item>
          <el-form-item
            v-show="ActivityForm.gov_join_limit == 1"
            label="可报名的协会："
          >
            <el-select
              v-model="ActivityForm.gov_join_projects"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in this.associationList"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="信息收集：" prop="join_form">
            <el-radio v-model="ActivityForm.join_form_enable" :label="1"
              >开启
            </el-radio>
            <el-radio v-model="ActivityForm.join_form_enable" :label="0"
              >关闭</el-radio
            >

            <p class="info" style="margin-bottom: 10px">
              <i class="el-icon-info"></i>提示：报名时需要额外收集的信息
            </p>

            <!-- 报名表单列表 -->
            <div v-if="ActivityForm.join_form_enable">
              <activity-join-forms
                v-if="
                  ActivityForm.join_form &&
                  ActivityForm.join_form.length &&
                  !ActivityForm.activityForm
                "
                style="width: 900px"
                :joinForms="ActivityForm.join_form"
              >
              </activity-join-forms>
              <el-form-item v-else label="">
                <el-button
                  style="margin-right: 10px"
                  type="primary"
                  @click="formListDialog.show = true"
                  >选择</el-button
                >
                <span
                  v-if="
                    ActivityForm.activity_form_id &&
                    ActivityForm.activityForm &&
                    ActivityForm.activityForm.name
                  "
                  >已选：{{ ActivityForm.activityForm.name }}  <el-button @click="toFormDesign(ActivityForm.activity_form_id, ActivityForm.activityForm.name)" size="large" type="text">修改表单</el-button></span
                >
              </el-form-item>
            </div>
          </el-form-item>

          <!-- <el-form-item label="着装要求：" prop="dress">
            <el-input
              type="textarea"
              v-model="ActivityForm.dress"
              placeholder="请填写着装要求"
              rows="5"
              maxlength="50"
              show-word-limit
            ></el-input>
            <p class="info">
              <i class="el-icon-info"></i>活动状态未开始、报名中才显示
            </p>
          </el-form-item>
          <el-form-item label="报名说明：" prop="sign_explain">
            <el-input
              type="textarea"
              v-model="ActivityForm.sign_explain"
              placeholder="请填写报名说明"
              rows="5"
              maxlength="50"
              show-word-limit
            ></el-input>
            <p class="info">
              <i class="el-icon-info"></i>活动状态未开始、报名中才显示
            </p>
          </el-form-item> -->
        </div>
      </div>
    </el-form>

    <activity-form-list-dialog
      :show="formListDialog.show"
      @close="formListDialog.show = false"
      @confirm="onChooseForm"
    ></activity-form-list-dialog>
  </div>
</template>
<script>
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'
import { getCityData } from '@/modules/organization-admin/api/org-frame/custom-form'
import {
  positionLevel,
  joinGovList,
} from '@/modules/activity/api/activity-list/detail.js'

import ActivityStage from '@/modules/activity/components/ActivityList/ActivityStage.vue'
import ActivityPriceStage from '@/modules/activity/components/ActivityList/ActivityPriceStage.vue'
import ActivityJoinForms from '@/modules/activity/components/ActivityList/ActivityJoinForms/ActivityJoinForms.vue'
import ActivityFormListDialog from '@/modules/activity/components/activity-form/ActivityFormListDialog'

export default {
  model: {
    prop: 'form',
    event: 'updateForm',
  },
  components: {
    ActivityFormListDialog,
    SingleMediaWall,

    ActivityStage,
    ActivityJoinForms,
    ActivityPriceStage,
  },
  props: ['form'],
  data() {
    return {
      // 支付类型
      payTypeOptions: [
        { label: '金额(元)', id: 0 },
        { label: '积分', id: 1 },
      ],
      // 报名时间选择范围设置
      pickerJoinOptions: {
        disabledDate: (time) => {
          return this.selectableJoinDate(time)
        },
      },
      // 早鸟时间选择范围设置
      pickerEarlyOptions: {
        disabledDate: (time) => {
          return this.selectableEarlyDate(time)
        },
      },
      // 职务数组
      positionOptions: [],
      // 职务数组props
      props: {
        multiple: true,
        label: 'name',
        value: 'id',
        children: 'position',
        emitPath: false,
        expandTrigger: 'hover',
      },
      rules: {
        join_positions: [
          { required: true, message: '请选择职务', trigger: 'change' },
        ],
        activityJoinTime: [
          { required: true, message: '请选择报名时间', trigger: 'change' },
        ],
      },
      optionProp: {
        expandTrigger: 'hover',
        multiple: true,
        emitPath: false,
      },
      //关联协会列表
      associationList: [],
      // 阶段报名职务选择
      selectablePositions: [],
      // 职务报名价格职务选择
      selecPricePositions: [],
      CityDataList: [],

      formListDialog: {
        show: false,
      },
    }
  },
  watch: {
    positionOptions(val) {
      this.positionSelected(this.ActivityForm.join_positions)
    },
  },
  created() {
    this.getCity()
    // 获取职务
    this.getPositionLevel()
    joinGovList().then(({ data }) => {
      this.associationList = data
    })
  },
  methods: {
    toFormDesign(id, title) {
      this.$router.push({
        name: 'ActivityFormDesign',
        params: {id},
        query: {
          title: encodeURIComponent(title)
        }
      })
    },
    onChooseForm(detailList = []) {
      const { id, name, form_config } = detailList[detailList.length - 1]
      this.ActivityForm.activity_form_id = id
      if (this.ActivityForm.activityForm) {
        this.ActivityForm.activityForm.id = id
        this.ActivityForm.activityForm.name = name
        this.ActivityForm.activityForm.form_config = form_config
      } else {
        this.ActivityForm.activityForm = {
          id,
          name,
          form_config,
        }
      }
      this.formListDialog.show = false
    },
    // 选择早鸟价时间
    handleSelectEarlyPriceTime(value) {
      if (value && value.length) {
        this.ActivityForm.early_price_start_time = value[0] / 1000
        this.ActivityForm.early_price_end_time = value[1] / 1000
      } else {
        this.ActivityForm.early_price_start_time =
          this.ActivityForm.early_price_end_time = ''
      }
    },

    // 选择报名时间
    handleSelectJoinTime(value) {
      console.log(value)
      if (value && value.length) {
        this.ActivityForm.join_start_time = value[0] / 1000
        this.ActivityForm.join_end_time = value[1] / 1000
      } else {
        this.ActivityForm.join_start_time = this.ActivityForm.join_end_time = ''
      }
      console.log(
        this.ActivityForm.join_start_time,
        this.ActivityForm.join_end_time
      )
    },
    // 获取职务
    getPositionLevel() {
      positionLevel({
        hashid_enable: 0,
        is_list_tree: 0,
        is_show_all: 0,
        data_auth_filter: 0,
      }).then((res) => {
        this.positionOptions = res.data
        // 设置职务报名选择
        let positions = []
        this.positionOptions.forEach((item1) => {
          item1.position.forEach((item2) => {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          })
        })
        this.selecPricePositions = positions
      })
    },
    // 报名时间选择范围设置
    selectableJoinDate(time) {
      if (this.ActivityForm?.activity_start_time) {
        return time.getTime() > this.ActivityForm.activity_start_time * 1000
      } else {
        return false
      }
    },
    // 早鸟价选择范围设置
    selectableEarlyDate(time) {
      if (this.ActivityForm.join_start_time) {
        return !(
          time.getTime() >= this.ActivityForm.join_start_time * 1000 &&
          time.getTime() <= this.ActivityForm.join_end_time * 1000
        )
      } else {
        return false
      }
    },
    // 选择报名要求
    positionSelected(e = null) {
      let positions = []
      this.positionOptions.forEach((item1) => {
        item1.position.forEach((item2) => {
          if (e && e.includes(item2.id)) {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          }
          if (!e || !e.length) {
            let position_text = item1.name + '/' + item2.name
            positions.push({ id: item2.id, position_name: position_text })
          }
        })
      })
      this.selectablePositions = positions
    },
    // 获取地理数据
    getCity() {
      getCityData().then((res) => {
        this.CityDataList = res.data
      })
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form
      },
      set(val) {
        this.$emit('updateForm', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    // 开启了协会互联？true ：false
    tenantGov() {
      return (
        this.$store.getters.tenant.features?.includes(
          'admin_gov_data_manage'
        ) || false
      )
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
  mounted() {
    // this.positionSelected(this.ActivityForm.join_positions)
  },
}
</script>

<style lang="scss" scoped>
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}

.tips {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 8px;
  color: #909399;
}

.modular-content {
  margin-left: 11px;
}
</style>
