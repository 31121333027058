<template>
  <div v-loading="loading" class="sign-list">
    <Reminder :textArr="ReminderText" />

    <!-- <el-button type="primary" @click="addUser">单个添加报名</el-button> -->
    <el-button type="primary" @click="openImportDialog"
      >批量导入报名名单</el-button
    >
    <el-button type="primary" @click="openTemporaryDialog">临时报名</el-button>
    <el-button type="primary" @click="openInviteUserDialog">定向邀请</el-button>

    <list-tabs
      v-model="filterForm.nav"
      :isLoading="loading"
      :tabs="
        tenantVersion === 'school'
          ? schoolNavArr
          : !isCycOrganization
          ? freeArr
          : navArr
      "
      @tab-click="handleNavClick($event, 'nav')"
    >
    </list-tabs>
    <JoinListFilter
      :filterForm="filterForm"
      :ids="selectArr"
      :uploadFilter="ok"
    />
    <list-tabs
      v-if="filterForm.nav === 'non_cycuser'"
      v-model="filterForm.tab"
      :isLoading="loading"
      :tabs="tabArr"
      @tab-click="handleNavClick($event, 'tab')"
    >
    </list-tabs>
    <div
      class="handle-btn"
      :style="filterForm.nav !== 'non_cycuser' ? 'padding-top: 20px;' : ''"
    >
      <template
        v-if="filterForm.nav === 'non_cycuser' && filterForm.tab === 'checking'"
      >
        <el-button type="primary" size="small" @click="handlePass(1)"
          >通过</el-button
        >
        <el-button size="small" type="danger" @click="handlePass(0)"
          >不通过</el-button
        >
      </template>
      <template v-else>
        <el-button
          v-if="
            filterForm.nav === 'cycuser' ||
            (filterForm.nav === 'non_cycuser' && filterForm.tab === 'checked')
          "
          type="primary"
          size="small"
          @click="addMember(filterForm.nav)"
          >添加</el-button
        >
        <el-button
          @click="handleDelete"
          size="small"
          type="danger"
          v-if="filterForm.nav !== 'canceled'"
          >移除</el-button
        >
        <el-button
          @click="refoundSelectMember"
          size="small"
          type="danger"
          v-if="filterForm.nav !== 'canceled'"
          >退还费用</el-button
        >
      </template>
    </div>
    <el-table
      :data="activityList"
      class="thead-light"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      @sort-change="sortChange"
      id="sortTable"
      row-key="id"
    >
      <!-- 勾选 -->
      <el-table-column
        v-if="filterForm.nav !== 'canceled'"
        class="select-check"
        fixed
        type="selection"
        width="55"
      ></el-table-column>
      <!-- 操作 -->
      <el-table-column
        :width="
          filterForm.nav === 'non_cycuser' && filterForm.tab === 'checking'
            ? 250
            : filterForm.nav === 'all' ||
              (filterForm.nav === 'non_cycuser' &&
                filterForm.tab === 'unchecked')
            ? 70
            : 200
        "
        label="操作"
        v-if="filterForm.nav !== 'canceled'"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="small"
            v-if="
              filterForm.nav === 'cycuser' ||
              (filterForm.nav === 'non_cycuser' && filterForm.tab === 'checked')
            "
            type="text"
            @click="checkJoinRow(scope.row)"
            >查看报名信息
          </el-button>
          <template
            v-if="
              filterForm.nav === 'non_cycuser' && filterForm.tab === 'checking'
            "
          >
            <el-button size="small" type="text" @click="PassRow(scope.row, 1)"
              >通过
            </el-button>
            <el-button size="small" type="text" @click="PassRow(scope.row, 0)"
              >不通过
            </el-button>
            <el-button @click="checkJoinRow(scope.row)" size="small" type="text">查看报名信息</el-button>
          </template>
          <!-- 更多 -->
          <MoreBtn v-else trigger="hover" @command="onMoreCommand">
<!--            <el-dropdown-item :command="{type: 'files', row: scope.row}">导出附件</el-dropdown-item>-->
            <el-dropdown-item
              v-if="
                filterForm.nav === 'cycuser' ||
                (filterForm.nav === 'non_cycuser' &&
                  filterForm.tab === 'checked')
              "
              :command="{ type: 'editSignRow', row: scope.row }"
              >修改签到状态</el-dropdown-item
            >
            <el-dropdown-item :command="{ type: 'deleteRow', row: scope.row }"
              >移除</el-dropdown-item
            >
            <!-- 后台添加的不显示退还 -->
            <el-dropdown-item
              v-if="scope.row.pay_status == '已支付'"
              :command="{ type: 'refoundRow', row: scope.row }"
              >退还费用</el-dropdown-item
            >
          </MoreBtn>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column
          show-overflow-tooltip
          :prop="th.prop"
          :label="th.label"
          :min-width="th.minWidth || ''"
          :key="index"
        >
          <template slot-scope="scope">
            <!-- 订单状态显示 -->
            <template
              v-if="th.prop === 'pay_status' && scope.row[th.prop] !== '--'"
            >
              <ListStatusText
                :text="scope.row[th.prop]"
                :type="scope.row[th.prop] == '已支付' ? 'success' : 'info'"
              ></ListStatusText>
            </template>
            <!-- 额外费用 -->
            <div v-else-if="th.prop === 'extra_money'">
              <span style="margin-right: 10px">{{
                formatExtraSum(getExtraSum(scope.row.join_form)) | placeholder
              }}</span>
              <el-button
                v-if="
                  getExtraSum(scope.row.join_form).money +
                  getExtraSum(scope.row.join_form).integral
                "
                type="text"
                @click="showExtra(scope.row.join_form)"
                >查看</el-button
              >
            </div>
            <!-- 普通 -->
            <template v-else>
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    >
    </Pagination>
    <JoinMemberSelector
      v-model="openSelectMember"
      :Members="activityList"
      :update="reflashList"
    />
    <JoinNotMemberSelector
      v-model="openSelectNonMember"
      :Members="activityList"
      :update="reflashList"
    />
    <EditSignStatus
      v-model="openSignStatus"
      :id="currentRow.id"
      :sign_status="currentRow.sign_time"
      :updateList="reflashList"
    />
    <JoinNotMemberCheck
      v-model="openReason"
      :IDs="ids"
      :updateList="reflashList"
    />
    <JoinMemberDialog
      v-model="openJoinDetail"
      :id="row_id"
      :updateList="reflashList"
    />
    <RemarkDialog
      v-model="openRemarkDialog"
      :id="row_id"
      :updateList="reflashList"
      :row_remark="row_remark"
    />
    <!-- 临时报名弹窗 -->
    <TemporarySignDialog
      :showCode="openTemporary"
      @closeCode="closeTemporaryDialog"
      :activity_id="this.filterForm.id"
    >
    </TemporarySignDialog>

    <!-- 定向邀请弹窗 -->
    <InviteUserDialog v-model="openInviteUser"></InviteUserDialog>
    <!-- 退还费用弹窗 -->
    <RefoundDialog
      v-model="openRefound"
      :currentRow="currentRow"
      @updateList="reflashList"
      :ids="refoundIds"
    ></RefoundDialog>
    <!-- 报名名单添加会员弹窗 -->
    <SignUpMemberDialog
      @update="reflashList"
      v-model="openAddUser"
      :is_non_cycuser="is_non_cycuser"
    ></SignUpMemberDialog>
    <!-- 额外费用 -->
    <extra-dialog :extra-dialog="extraDialog" />
    <!-- 批量导入弹窗 -->
    <ImportSignList
      v-model="openImport"
      :updateList="reflashList"
    ></ImportSignList>
  </div>
</template>

<script>
import Reminder from '@/modules/common/components/Reminder.vue'
import Pagination from '@/base/components/Default/Pagination'
import MoreBtn from '@/base/components/Table/MoreBtn'

import {
  getActivityUser,
  delJoinUser,
  getActivityUserSum,
  check,
} from '@/modules/activity/api/sign-up-list/list.js'
import EditSignStatus from '@/modules/activity/components/ActivityList/EditSignStatus.vue'
import JoinListFilter from '@/modules/activity/components/ActivityList/JoinListFilter.vue'
import JoinMemberSelector from '@/modules/activity/components/ActivityList/JoinMemberSelector'
import JoinNotMemberSelector from '@/modules/activity/components/ActivityList/JoinNotMemberSelector'
import JoinNotMemberCheck from '@/modules/activity/components/ActivityList/JoinNotMemberCheck'
import JoinMemberDialog from '@/modules/activity/components/ActivityList/JoinMemberDialog'
import RemarkDialog from '@/modules/activity/components/ActivityList/RemarkDialog'
import ListStatusText from '@/base/components/List/ListStatusText'

import TemporarySignDialog from '@/modules/activity/components/ActivityList/TemporarySignDialog'
import InviteUserDialog from '@/modules/activity/components/ActivityList/InviteUserDialog'

import SignUpMemberDialog from '@/modules/activity/components/ActivityList/SignUpList/SignUpMemberDialog'
import ImportSignList from '@/modules/activity/components/ActivityList/SignUpList/ImportSignList'
import RefoundDialog from '@/modules/activity/components/ActivityList/SignUpList/RefoundDialog'
import ExtraDialog from '@/modules/activity/components/ActivityList/SignUpList/ExtraDialog'
import {exportActivityFormFiles} from '../../../api/activity-list/list'
import {exportFilesTool} from '../../../../common/utils/exportFiles'

const theadConfig = require('@/modules/activity/assets/json/sign-up-list-thead.json')

export default {
  data() {
    return {
      loading: false,
      openTag: true,
      openSelectMember: false,
      openSelectNonMember: false,
      openSignStatus: false,
      // 临时报名弹窗
      openTemporary: false,
      // 定向邀请弹窗
      openInviteUser: false,
      ReminderText: [
        '本次活动报名人数限制 0 人',
        '当前已报名 0 人',
        '活动签到人数 0 人',
      ],
      // 一级导航配置
      navArr: [
        { label: '会员', name: 'cycuser' },
        { label: '游客', name: 'non_cycuser' },
        // { label: "报名表单", name: "all" },
        { label: '已取消', name: 'canceled' },
      ],
      freeArr: [
        { label: '游客', name: 'non_cycuser' },
        { label: '已取消', name: 'canceled' },
      ],
      // 校友导航一级配置
      schoolNavArr: [
        { label: '校友', name: 'cycuser' },
        { label: '非校友', name: 'non_cycuser' },
        // { label: "报名表单", name: "all" },
      ],
      // 二级导航配置
      tabArr: [
        { label: '已通过', name: 'checked' },
        { label: '待审核', name: 'checking', badge: 1 },
        { label: '未通过', name: 'unchecked' },
      ],
      activityList: [],
      // timer: null,
      selectArr: [],
      ids: [],
      openReason: false,
      filterForm: {
        id: '',
        nickname: '',
        phone: '',
        position_ids: [], // 会内职务
        order_status: -1, // 订单状态
        user_type: -1, //身份类型
        join_start_time: -1,
        join_end_time: -1,
        sign_type: -1,
        sign_status: -1,
        nav: 'cycuser',
        tab: 'checked',
        page_size: 15,
      },
      filters: {
        cycuser: null,
        all: null,
        checked: null,
        checking: null,
        unchecked: null,
      },
      pageData: {},
      join_status: {},
      currentRow: { id: 0, sign_time: 0 },
      openJoinDetail: false,
      row_id: 0,
      // 修改备注弹框
      openRemarkDialog: false,
      // 当前行的备注
      row_remark: '',
      // 添加会员弹框
      openAddUser: false,
      // 是不是非会员
      is_non_cycuser: 0,
      extraDialog: {
        show: false,
        data: [],
      },
      // 报名列表导入弹窗控制
      openImport: false,
      // 退款费用弹窗
      openRefound: false,
      // 批量退还ids
      refoundIds: [],
    }
  },
  components: {
    ExtraDialog,
    Pagination,
    JoinMemberSelector,
    JoinNotMemberSelector,
    EditSignStatus,
    JoinListFilter,
    Reminder,
    JoinNotMemberCheck,
    JoinMemberDialog,
    ListStatusText,
    RemarkDialog,
    TemporarySignDialog,
    InviteUserDialog,
    SignUpMemberDialog,
    ImportSignList,
    RefoundDialog,
    MoreBtn,
  },
  computed: {
    thead() {
      return this.filterForm.nav !== 'non_cycuser'
        ? theadConfig[this.filterForm.nav]
        : theadConfig[this.filterForm.tab]
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
  created() {
    this.filterForm.id = this.$route.params.id
    if (!this.isCycOrganization) {
      this.filterForm.nav = 'non_cycuser'
    }
    this.updataActivityUser(this.filterForm)
    this.ActivityUserSum()
    console.log(this.thead)
    // if (this.timer) clearInterval(this.timer);
    // this.timer = setInterval(() => {

    // }, 3000);
  },
  // beforeDestroy() {
  //   if (this.timer) clearInterval(this.timer);
  // },
  methods: {
    //更多按钮点击事件
    onMoreCommand({ type, row }) {
      switch (type) {
        case 'checkJoinRow': //查看报名信息
          this.checkJoinRow(row)
          break
        case 'editSignRow': //修改签到状态
          this.editSignRow(row)
          break
        case 'deleteRow': //移除
          this.deleteRow(row)
          break
        case 'refoundRow': //退还费用
          this.refoundRow(row)
          break
        case 'files':
          this.onExportFiles(row.id)
      }
    },
    async onExportFiles(id) {
      const postData = { ...this.filterForm, export_ids: [id] }
      this.$message.success('正在打包附件...请稍后')
      const {data} = await exportActivityFormFiles(postData).catch(() => ({data: null}))
      if (!data) return // 接口报错
      const {list} = data;
      if (!list.length) {
        this.$message.info('没有需要导出的附件')
        return
      }
      this.$message.success('开始下载...请稍后')
      const exportTool = new exportFilesTool({
        // onProgress: (percent) => this.progress = percent
      })
      await exportTool.start(list)
      // this._ctx = exportTool
      this.$message.success('下载成功！')

      console.log('全部结束')
      // this.progress = '' // 进度日志消失
    },
    showExtra(formList) {
      this.extraDialog.data = formList
      this.extraDialog.show = true
    },
    getExtraSum(formList = []) {
      formList = formList || []
      const total = {
        money: 0,
        integral: 0,
      }
      formList.forEach((f) => {
        const user_data = f.text_value
        const is_select = f.type !== 'input' && f.is_user_data !== 1
        const is_arr = is_select && user_data && typeof user_data !== 'string'
        if (!is_arr) {
          if (is_select) {
            f.options?.forEach((ff) => {
              if (user_data === ff.text) {
                /* pay_type 支付方式：0-金额、1-积分 */
                if (ff.pay_type) total.integral += ff.pay_amount
                else total.money += ff.pay_amount
              }
            })
          } else {
            /* 输入框类型不需要付费 */
          }
        } else {
          f.options?.forEach((ff) => {
            if (user_data.includes(ff.text)) {
              /* pay_type 支付方式：0-金额、1-积分 */
              if (ff.pay_type) total.integral += ff.pay_amount
              else total.money += ff.pay_amount
            }
          })
        }
      })
      return total
    },
    formatExtraSum(total) {
      const money = `￥${total.money}`
      const integral = `${total.integral}积分`
      const arr = []
      if (total.money) arr.push(money)
      if (total.integral) arr.push(integral)
      return arr.join(' + ')
    },
    //报名名单添加会员
    addUser() {
      this.openAddUser = true
    },
    // 关闭临时报名弹窗
    closeTemporaryDialog() {
      this.openTemporary = false
    },
    // 打开临时报名弹窗
    openTemporaryDialog() {
      this.openTemporary = true
    },
    // 打开批量导入报名名单弹窗弹窗
    openImportDialog() {
      this.openImport = true
    },
    // 打开定向邀请弹窗
    openInviteUserDialog() {
      this.openInviteUser = true
    },
    closeTip() {
      this.openTag = false
    },

    // 获取温馨提示相关数据
    ActivityUserSum() {
      getActivityUserSum({ id: this.filterForm.id })
        .then((res) => {
          const { data } = res
          this.join_status = data
          this.ReminderText = [
            `本次活动报名人数${
              this.join_status.join_limit
                ? '限制 ' + this.join_status.join_limit + ' 人'
                : '不限'
            }`,
            `当前已报名 ${this.join_status.join_count} 人`,
            `活动签到人数 ${this.join_status.sign_count}/${this.join_status.join_count} 人`,
          ]
          //  && data.reserves_count > 0
          if (data.reserve_status) {
            this.ReminderText.push(`活动留位人数 ${data.reserves_count} 人`)
          }
        })
        .catch(() => {})
    },
    updataActivityUser(requestData) {
      this.loading = true
      this.pageData = {}
      getActivityUser(requestData).then((res) => {
        const { data } = res
        this.activityList = data.data
        this.pageData = data
        // 记录筛选条件缓存
        let filterFormKey =
          this.filterForm.nav !== 'non_cycuser'
            ? this.filterForm.nav
            : this.filterForm.tab
        this.filters[filterFormKey] = { ...this.filterForm }
        // 修改报名费用表头文案
        console.log(this.thead)
        this.thead?.map((el) => {
          if (el.prop === 'pay_money') {
            el.label =
              data.pay_type === '免费'
                ? `报名费用：${data.pay_type}`
                : `报名费用（${data.pay_type}）`
          }
        })
        this.loading = false
        this.ActivityUserSum()
      })
    },
    // 点击添加会员
    addMember(membertype) {
      if (
        this.join_status.join_limit > this.activityList.length ||
        this.join_status.join_limit === 0
      ) {
        // if (membertype === "cycuser") {
        //   this.openSelectMember = true;
        // } else {
        //   this.openSelectNonMember = true;
        // }
        if (membertype === 'cycuser') {
          // 是会员
          this.is_non_cycuser = 0
        } else {
          // 非会员
          this.is_non_cycuser = 1
        }
        this.openAddUser = true
      } else {
        this.$message.error('当前报名人数已达上限')
      }
    },
    // 获取选择会员
    handleSelect(e) {
      this.activityList = e
    },
    // 修改签到状态
    editSignRow(row) {
      this.currentRow = row
      this.openSignStatus = true
    },
    // 修改备注
    editRemakeRow(row) {
      console.log(row)
      this.row_id = row.id
      this.row_remark = row.remark
      this.openRemarkDialog = true
    },
    //点击列表移除
    deleteRow(row) {
      this.$msgbox
        .confirm(
          '是否将该数据移除报名，移除后退还全部报名费用' ||
            '确定要移除该数据吗？',
          '提示',
          {
            type: 'error',
          }
        )
        .then(() => {
          delJoinUser({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg)
              this.reflashList()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 批量移除
    handleDelete() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm(
            '是否将这些数据移除报名，移除后退还全部报名费用' ||
              '确定要移除选中数据吗？',
            '提示',
            {
              type: 'error',
            }
          )
          .then(() => {
            this.loading = true
            delJoinUser({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg)
                this.reflashList()
              })
              .catch(() => {
                this.loading = false
              })
          })
          .catch(() => {})
      } else {
        this.$message.info('请选择数据项')
      }
    },
    //  退还费用
    refoundRow(row) {
      this.currentRow = row
      this.refoundIds = []
      // 打开退款费用弹窗
      this.openRefound = true
    },
    refoundSelectMember() {
      if (this.selectArr.length) {
        this.refoundIds = this.selectArr
        // 打开退款费用弹窗
        this.openRefound = true
      } else {
        this.$message.info('没有选择数据项！')
      }
    },
    // 列表勾选事件
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = []
      } else {
        this.selectArr = []
        //筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id)
        })
      }
    },
    // 批量通过/不通过
    handlePass(status) {
      if (this.selectArr.length) {
        if (status === 1) {
          this.CheckedDialog(this.selectArr, 'more')
        } else {
          this.UnCheckedDialog(this.selectArr)
        }
      } else {
        this.$message.info('请选择数据项')
      }
    },
    // 点击列表通过/不通过
    PassRow(row, status) {
      if (status === 1) {
        this.CheckedDialog([row.id])
      } else {
        this.UnCheckedDialog([row.id])
      }
    },
    //审核通过弹窗
    CheckedDialog(ids, key) {
      if (key === 'more') {
        this.$msgbox
          .confirm(
            '确定通过选中的用户吗？将通过短信、订阅消息、系统消息通知用户',
            '提示',
            {
              type: 'info',
            }
          )
          .then((res) => {
            this.pass({ ids })
          })
          .catch((err) => {})
      } else {
        this.$msgbox
          .confirm(
            '确定通过该用户吗？将通过短信、订阅消息、系统消息通知用户',
            '提示',
            {
              type: 'info',
            }
          )
          .then((res) => {
            this.pass({ ids })
          })
          .catch((err) => {})
      }
    },
    //审核不通过弹窗
    UnCheckedDialog(ids) {
      this.ids = ids
      this.openReason = true
    },
    //审核通过操作
    pass(data) {
      this.loading = true
      check(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.reflashList()
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 查看详情
    CheckRow(row) {
      this.row_id = row.id
      this.openJoinDetail = true
    },
    // 查看报名信息信息
    checkJoinRow(row) {
      this.$router.push({
        name: 'SignUpDetail',
        params: {
          id: this.$route.params.id,
          jid: row.id,
        },
      })
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === 'ascending'
        ? 0
        : 1
      this.filterForm.order_by = column.prop
      this.updataActivityUser(this.filterForm)
    },
    // 筛选回调
    ok(e) {
      const pageData = {}
      this.filterForm = { ...this.filterForm, ...e, page: 1 }
      this.updataActivityUser({ ...this.filterForm, ...pageData })
    },
    // 点击一级导航栏
    handleNavClick(e, type) {
      let filterFormKey =
        e.name !== 'non_cycuser' ? e.name : this.filterForm.tab
      if (this.filters[filterFormKey]) {
        this.filterForm = this.filters[filterFormKey]
      } else {
        this.filterForm = {
          id: this.filterForm.id,
          nickname: '',
          phone: '',
          position_ids: [], // 会内职务
          order_status: -1, // 订单状态
          user_type: -1, //身份类型
          join_start_time: -1,
          join_end_time: -1,
          sign_type: -1,
          sign_status: -1,
          nav: type === 'nav' ? e.name : this.filterForm.nav,
          tab: type === 'tab' ? e.name : this.filterForm.tab, //当前筛选tab
          page_size: this.pageData.per_page, //每页多少条数据
        }
      }
      this.updataActivityUser(this.filterForm)
    },
    // 刷新列表
    reflashList() {
      this.updataActivityUser({
        page: this.pageData.current_page,
        ...this.filterForm,
      })
    },
    changPage(data) {
      this.filterForm.page_size = data.page_size
      const pageData = { ...this.filterForm, ...data }
      this.updataActivityUser(pageData)
    },
  },
}
</script>

<style scoped lang="scss">
.sign-list {
  padding: 0 0px 20px;
  .tip-tag {
    height: 40px;
    line-height: 40px;
    width: 400px;
    padding: 0 16px;
    font-size: 14px;
    border-color: #91d5ff;
    background-color: #e6f7ff;
  }

  .tip-tag span > span {
    color: rgba(0, 0, 0, 0.65);
    margin-right: 20px;
  }

  .tip-tag.el-tag .el-icon-close {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .tip-tag.el-tag .el-tag__close:hover {
    color: #fff;
  }

  .el-tag {
    background-color: #ebf1ff;
    border-color: #d7e4ff;
    display: inline-block;
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 12px;
    color: #3576ff;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: nowrap;
    width: 250px;
  }
  .el-tag .el-icon-close {
    left: 20px;
  }
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.handle-btn {
  padding: 0 0 20px;
}
</style>
